import "../scss/PaymentReceipt.css";
import MyButton from "./MyButton";
import { toInt } from "../utils/TextUtil";
import { datemonthYear, myDateDDMMyy } from "../utils/DateUtils";
import { numIn2Dec } from "../utils/RupeeUtil";
import LogoComponent from "./LogoComponent";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import PrintIcon from "@mui/icons-material/Print";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableContainer,
} from "@mui/material";
import { LocationOn, Email, Phone } from "@mui/icons-material";
import { breakAmt } from "../utils/MTBreakAmtUtil";
import { numberToWord } from "../utils/FormattingUtils";

let row_data = [];
// let rowMapping = [];
let totalAmount = 0;
let dateToday = new Date();
let date = dateToday.getDate();
let month = dateToday.getMonth() + 1;
let year = dateToday.getFullYear();
let separator = "-";
let invoiceDate = `${year}${separator}${
  month < 10 ? `0${month}` : `${month}`
}${separator}${date}`;

const AepsReceipt = () => {
  const [rowMapping, setRowMapping] = useState([]);
  row_data = JSON.parse(localStorage.getItem("selectedRow"));
  console.log("localStorage=> ", row_data);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [value, setValue] = useState(1);

  const [firstTime, setFirstTime] = useState(true);
  const [splitAmtArr, setSplitAmtArr] = useState([]);
  const [payment, setPayment] = useState();
  // console.log("splitamtarr", splitAmtArr);
  console.log("payment", payment);
  //   user = JSON.parse(localStorage.getItem("localUser"));

  const handleChange = (event) => {
    setValue(event.target.value * 1);
  };
  const dummyData = {
    outletName: "RAHUL TELECOM",
    mobileNo: "9999445469",
    beneficiary: "Sonu",
    accountNo: "37296396227",
    bankName: "Bank of Baroda",
    dateTime: "28/03/2024 06:39 PM",
    tid: "3729639622665567",
    utr: "40881977779",
    amount: 5000,
    status: "Success",
  };
  const totalAmountValue = row_data?.reduce((acc, payment) => acc + Number(payment?.amount || 0), 0);
  console.log("Total Amount:", totalAmountValue);

  useEffect(() => {
    // 1 is large 2 is small
    if (value === 1) {
      setRowMapping(
        row_data && row_data.length > 0
          ? // eslint-disable-next-line array-callback-return
            row_data.map((payment, index) => {
              setPayment(payment);
              //
              if (
                payment.operator === "Super Transfer" ||
                payment.operator === "Vendor Payments"
              ) {
                if (firstTime) totalAmount += toInt(payment?.amount);
                const amt_arr = breakAmt(payment?.amount, 5000);
                const arrData = amt_arr.map((splitItem, index) => {
                  return {
                    amount: splitItem,
                    created_at: payment.created_at,
                    number: payment.number,
                    ben_name: payment.ben_name,
                    operator: payment.operator,
                    ben_acc: payment.ben_acc,
                    ifsc: payment.ifsc,
                    op_id: payment.op_id,
                    status: payment.status,
                  };
                });
                let splitAmt = arrData.reverse();
                // console.log("amt_arr", amt_arr);
                setSplitAmtArr(splitAmt);
              } else {
                if (firstTime) totalAmount += toInt(payment?.amount);

                // console.log(`payment ${index} => ${JSON.stringify(payment, null, 2)}`)
                return (
                  <tr style={{ fontSize: "12px" }}>
                    <td>
                      {payment && payment.created_at
                        ? datemonthYear(payment.created_at)
                        : ""}
                    </td>
                    <td
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {payment && payment.number ? payment.number : " "} <br />
                      {payment && payment.ben_name ? payment.ben_name : " "}
                    </td>
                    <td
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {payment &&
                      payment.operator &&
                      (payment.operator === "Vendor Payments" ||
                        payment.operator === "Express Transfer" ||
                        payment.operator === "Domestic Money Transfer" ||
                        payment.operator === "Domestic Money Transfer 2")
                        ? "Money Transfer"
                        : payment.operator}
                    </td>
                    <td>
                      {payment && payment.ben_acc ? payment.ben_acc : ""}
                      <br />
                      {payment && payment.ifsc ? payment.ifsc : ""}
                    </td>
                    {/* <td>{payment && payment.ifsc ? payment.ifsc : ""}</td> */}
                    <td>{payment && payment.op_id ? payment.op_id : ""}</td>
                    <td>
                      {payment && payment.amount
                        ? `${numIn2Dec(payment.amount)} INR`
                        : ""}
                      <br />

                      {payment.operator === "Nepal Transfer" &&
                      payment &&
                      payment.amount
                        ? `${numIn2Dec(payment.amount * 1.6)} NPR`
                        : ""}
                    </td>
                    <td>{payment && payment.status ? payment.status : ""}</td>
                  </tr>
                );
              }
            })
          : []
      );
    } else {
      setRowMapping(
        row_data && row_data.length > 0
          ? row_data.map((payment, index) => {
              if (firstTime) totalAmount += toInt(payment?.amount);

              // console.log(`payment ${index} => ${JSON.stringify(payment, null, 2)}`)
              return (
                <div className="just-dashed-divider just-padding">
                  <tr style={{ fontSize: "12px" }}>
                    <td>Date</td>
                    <td>
                      {payment && payment.created_at
                        ? datemonthYear(payment.created_at)
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ fontSize: "12px", minHeight: "45px" }}>
                    <td>Operator</td>
                    <td
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {payment && payment.operator ? payment.operator : " "} <br />
                      {/* {payment && payment.ben_name ? payment.ben_name : " "} */}
                    </td>
                  </tr>
                  {/*  */}
                  <tr style={{ fontSize: "12px", minHeight: "45px" }}>
                    <td>Number</td>
                    <td
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {payment && payment.number ? payment.number : " "} <br />
                      {/* {payment && payment.ben_name ? payment.ben_name : " "} */}
                    </td>
                  </tr>
                  <tr style={{ fontSize: "12px", minHeight: "45px" }}>
                    <td>Bank Name</td>
                    <td
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {/* {payment && payment.number ? payment.number : " "} <br /> */}
                      {payment && payment.ifsc ? payment.ifsc : " "}
                    </td>
                  </tr>
                  
                  <tr style={{ fontSize: "12px" }}>
                    <td> Ref Number</td>
                    {/* <td>{payment && payment.op_id ? payment.op_id : ""}</td> */}
                    <td>
                      <td>{payment && payment.op_id ? payment.op_id : ""}</td>
                    </td>
                  </tr>

                  <tr style={{ fontSize: "12px" }}>
                    <td> Aadhar Number</td>
                    {/* <td>{payment && payment.op_id ? payment.op_id : ""}</td> */}
                    <td>
                      {payment?.ben_acc
                        ? `XXXX-XXXX-${payment.ben_acc.slice(-4)}`
                        : "NA"}
                    </td>
                  </tr>
                  {/*  */}
                  <tr style={{ fontSize: "12px" }}>
                    <td>Amount</td>
                    <td>
                      {payment && payment.amount
                        ? `${numIn2Dec(payment.amount)} INR`
                        : ""}
                      <br />
                      {payment.operator === "Nepal Transfer" &&
                      payment &&
                      payment.amount
                        ? `${numIn2Dec(payment.amount * 1.6)} NPR`
                        : ""}
                    </td>
                  </tr>
                  {/*  */}
                  <tr style={{ fontSize: "12px" }}>
                    <td>Status</td>
                    <td>{payment && payment.status ? payment.status : ""}</td>
                  </tr>
                </div>
              );
            })
          : []
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value === 1) {
      row_data &&
        row_data.length > 0 &&
        // eslint-disable-next-line array-callback-return
        row_data.map((payment) => {
          // if (payment.operator === "Super Transfer") {
          // console.log("here in oter effet");

          // }
          if (
            payment.operator === "Super Transfer" ||
            payment.operator === "Vendor Payments"
          ) {
            setRowMapping(
              splitAmtArr.map((splitItem) => {
                return (
                  <tr style={{ fontSize: "12px" }}>
                    <td>
                      {splitItem && splitItem.created_at
                        ? datemonthYear(splitItem.created_at)
                        : ""}
                    </td>
                    <td
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {splitItem && splitItem.number ? splitItem.number : " "}{" "}
                      <br />
                      {splitItem && splitItem.ben_name
                        ? splitItem.ben_name
                        : " "}
                    </td>
                    <td
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {splitItem && splitItem.operator
                        ? splitItem.operator === "Vendor Payments"
                          ? "Express Transfer"
                          : splitItem.operator
                        : ""}
                    </td>
                    <td>
                      {splitItem && splitItem.ben_acc ? splitItem.ben_acc : ""}
                      <br />
                      {splitItem && splitItem.ifsc ? splitItem.ifsc : ""}
                    </td>
                    {/* <td>{splitItem && splitItem.ifsc ? splitItem.ifsc : ""}</td> */}
                    <td>
                      {splitItem && splitItem.op_id ? splitItem.op_id : ""}
                    </td>
                    <td>
                      {splitItem && splitItem.amount
                        ? numIn2Dec(splitItem.amount)
                        : ""}
                    </td>
                    <td>
                      {splitItem && splitItem.status ? splitItem.status : ""}
                    </td>
                  </tr>
                );
              })
            );
          }
        });
    } else {
      row_data.length > 0 &&
        // eslint-disable-next-line array-callback-return
        row_data.map((payment) => {
          if (
            payment.operator === "Super Transfer" ||
            payment.operator === "Vendor Payments"
          ) {
            setRowMapping(
              splitAmtArr && splitAmtArr.length > 0
                ? splitAmtArr.map((splitItem) => {
                    // if (firstTime) totalAmount += toInt(payment?.amount);

                    // console.log(`payment ${index} => ${JSON.stringify(payment, null, 2)}`)
                    return (
                      <div className="just-dashed-divider just-padding">
                        <tr style={{ fontSize: "12px" }}>
                          <td>Date</td>
                          <td>
                            {splitItem && splitItem.created_at
                              ? datemonthYear(splitItem.created_at)
                              : ""}
                          </td>
                        </tr>
                        {/*  */}
                        <tr style={{ fontSize: "12px" }}>
                          <td>Customer</td>
                          <td
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            {splitItem && splitItem.number
                              ? splitItem.number
                              : " "}{" "}
                            <br />
                            {splitItem && splitItem.ben_name
                              ? splitItem.ben_name
                              : " "}
                          </td>
                        </tr>
                        {/*  */}
                        <tr style={{ fontSize: "12px" }}>
                          <td>Operator</td>
                          <td
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            {splitItem && splitItem.operator
                              ? splitItem.operator === "Vendor Payments"
                                ? "Express Transfer"
                                : splitItem.operator
                              : ""}
                          </td>
                        </tr>
                                               <tr style={{ fontSize: "12px" }}>
                          <td>Acc Details</td>
                          <td>
                            {splitItem && splitItem.ben_acc
                              ? splitItem.ben_acc
                              : ""}
                            <br />
                            {splitItem && splitItem.ifsc ? splitItem.ifsc : ""}
                          </td>
                        </tr>
                        {/*  */}
                        <tr style={{ fontSize: "12px" }}>
                          <td> Operator ID</td>
                          <td>
                            {splitItem && splitItem.op_id
                              ? splitItem.op_id
                              : ""}
                          </td>
                        </tr>
                        {/*  */}
                        <tr style={{ fontSize: "12px" }}>
                          <td>Amount</td>
                          <td>
                            {splitItem && splitItem.amount
                              ? numIn2Dec(splitItem.amount)
                              : ""}
                          </td>
                        </tr>
                        {/*  */}
                        <tr style={{ fontSize: "12px" }}>
                          <td>Status</td>
                          <td>
                            {splitItem && splitItem.status
                              ? splitItem.status
                              : ""}
                          </td>
                        </tr>
                      </div>
                    );
                  })
                : []
            );
          }
        });
    }
  }, [value, splitAmtArr]);

  if (value === 2) {
    return (
      <div className="like-parent-border">
        <div className="d-flex  justifycontent-center btnPrint">
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={(e) => {
                setFirstTime(false);
                handleChange(e);
              }}
              default
              row
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Large Receipt"
                // labelPlacement="top"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Small Receipt"
                // labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
     
        </div>
        <Grid className="parent-border-vertical">
          {/*  */}
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Grid>
              <LogoComponent width="60%" />
            </Grid>

            <Grid className="just-text-right" sx={{ fontWeight: "600" }}>
              {user ? user.establishment : "Null"}
              <br />
              {user ? user.username : "Null"}
            </Grid>
          </Grid>
          {/*  */}
          <div
            className="d-flex justify-content-center"
            style={{
              marginBottom: "0.2cm",
              marginTop: "0.2cm",
            }}
          >
            <h6>Transaction Summary</h6>
          </div>
          {/*  */}
          <div className="just-divider"></div>
          <table className="" style={{ borderSpacing: 0, width: "100%" }}>
            {rowMapping}
          </table>

          <div className="parent  parent-invoice-total mb-3">
            <span className="invoice-total-text child">TOTAL :</span>
            <span className="invoice-total child diff-fonts">
            ₹ {totalAmountValue}/-
            </span>
          </div>
          <div className="btnPrint d-flex justify-content-center mx-5 my-1">
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                window.print();
              }}
              sx={{
                backgroundColor: "#1E88E5",
                color: "#fff",
                padding: "9px 18px",
                borderRadius: "8px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#1565C0",
                },
                "@media print": {
                  display: "none",
                },
              }}
            >
              Print
            </Button>
          </div>
        </Grid>
      </div>
    );
  } else {
    return (
      <>
        <div className="like-parent-border btnPrint">
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={(e) => {
                setFirstTime(false);
                handleChange(e);
              }}
              default
              row
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Large Receipt"
                // labelPlacement="top"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Small Receipt"
                // labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Grid className="parent-border1">
          <Box sx={{ px: 2, py: 1, mt: 1.5 }}>
            <Grid container justifyContent="space-between" lg={12}>
              {/* Left Side - Logo */}
              <Grid item xs={12} lg={6} sm={6} sx={{ textAlign: "left" }}>
                <LogoComponent width="40%" />
              </Grid>
              <Grid
                className="just-text-right "
                sx={{ mt: 2, fontWeight: 600, gap: 3 }}
              >
                {user ? user.establishment : "Null"}
                <br />
                {user ? user.username : "Null"}
              </Grid>

              {/* Right Side - Location, Email, and Phone */}
            </Grid>

            <Box display="flex" justifyContent="center" textAlign="center">
              <Typography
                variant="body"
                sx={{
                  width: "auto",
                  backgroundColor: "#E3F2FD",
                  color: "#0D47A1",
                  padding: "10px 30px",
                  textAlign: "center",
                  borderRadius: "25px",
                  border: "2px solid #90CAF9",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  fontSize: "1rem",
                  mt: 5,
                  fontWeight: 600,
                }}
              >
                Transaction Summary
              </Typography>
            </Box>

            <TableContainer
  sx={{
    mt: 3,
    color: "#1A237E",
    fontWeight: 700,
    border: "1px solid #BBDEFB",
    borderRadius: "8px",
    overflow: "hidden",
  }}
>
  <Table>
    <TableHead>
      <TableRow>
        {[
          "Date",
          "Operator",
          "Bank Name",
          "Number",
          "Aadhaar Number",
          "Ref Number",
          "Amount",
          "Status",
        ].map((header, index) => (
          <TableCell
            key={index}
            sx={{
              border: "1px solid #64B5F6",
              backgroundColor: "#E3F2FD",
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
              p: 1,
              width: `${100 / 9}%`,
            }}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {row_data?.map((payment, index) => (
        <TableRow key={index}>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {datemonthYear(payment?.created_at)}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.operator || "N/A"}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.ifsc || "N/A"}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.number || "N/A"}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.ben_acc ? `XXXX-XXXX-${payment.ben_acc.slice(-4)}` : "NA"}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.op_id || "N/A"}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              color: payment?.amount < 0 ? "green" : "red",
              fontWeight: "bold",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
             
            }}
          >
               {payment?.amount}
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid #64B5F6",
              fontSize: "0.95rem",
              textAlign: "center",
              whiteSpace: "normal",
              wordBreak: "break-word",
              p: 1,
            }}
          >
            {payment?.status || "N/A"}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


            <Grid
              sx={{
                justifyContent: "space-between",
                display: "flex",
                color: "#256BC5",
              }}
            >
              <Grid sx={{ justifyContent: "start" }}>
                <Box
                  sx={{
                    justifyContent: "start",
                    display: "flex",
                    mt: 3,
                    color: "#0D47A1",
                    fontWeight: 700,
                    fontSize: "1.35rem",
                  }}
                >
                  <Typography variant="body1">
                    Total Amount: <strong>₹ {totalAmountValue}/-</strong>
                  </Typography>
                </Box>
                <Box sx={{ justifyContent: "start", display: "flex", mt: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#0D47A1",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Amount (In Words):{" "}
                    <strong>
                      {(numberToWord(totalAmountValue) || "").toUpperCase()} /-
                    </strong>
                  </Typography>
                </Box>
              </Grid>

              <Grid mt={3} sx={{ textAlign: "right", mr: 1, fontSize: "12px" }}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                      window.print();
                    }}
                    sx={{
                      backgroundColor: "#1E88E5",
                      color: "#fff",
                      padding: "9px 18px",
                      borderRadius: "8px",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#1565C0",
                      },
                      "@media print": {
                        display: "none",
                      },
                    }}
                  >
                    Print
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#0D47A1",
                mt: 7,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                2024 All Rights Reserved
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  textAlign: "right",
                }}
              >
                This is a system generated receipt, hence no seal or signature
                required.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </>
    );
  }
};

export default AepsReceipt;
