import React, { useState } from "react";
import {
  Tooltip,
  // Snackbar,
  // IconButton,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useContext } from "react";
import PrintIcon from "@mui/icons-material/Print";
import CachedIcon from "@mui/icons-material/Cached";
import moment from "moment";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AuthContext from "../../store/AuthContext";
import ApiEndpoints from "../../network/ApiEndPoints";
import { get } from "../../network/ApiController";
import { json2Csv, json2Excel } from "../../utils/exportToExcel";
import { apiErrorToast } from "../../utils/ToastUtil";
import useCommonContext from "../../store/CommonContext";
import { ddmmyy, dateToTime } from "../../utils/DateUtils";
import { currencySetter } from "../../utils/Currencyutil";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RaiseIssueModal from "../../modals/RaiseIssueModal";
import {
  AD_REPORTS,
  Categories,
  REPORTS,
  Transaction_Tab,
  mt_tab_value,
  nonAdminColOptions,
  searchOptions,
} from "../../utils/constants";
import ApiPaginateSearch from "../../component/ApiPaginateSearch";
import ExcelUploadModal from "../../modals/ExcelUploadModal";
import RefreshComponent from "../../component/RefreshComponent";
import FilterModal from "../../modals/FilterModal";
import { primaryColor } from "../../theme/setThemeColor";
import { CustomStyles } from "../../component/CustomStyle";
import FilterCard from "../../modals/FilterCard";
// import { Icon } from "@iconify/react";
import RightSidePannel from "../../component/transactions/RightSidePannel";
import CustomTabs from "../../component/CustomTabs";
import RetDbTransactionTab from "../../component/Tab/RetDbTransactionTab";
import CommonStatus from "../../component/CommonStatus";
import StatusDisplay from "../../StatusDisplay";
// eslint-disable-next-line no-unused-vars
let refreshFilter;
let refresh;

let handleCloseModal;
const RetDdTransactionView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState("");
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const role = user?.role.toLowerCase();
  const [state, setState] = useState(false);
  const [rowData, setRowData] = useState({});
  const [showOldTransaction, setShowOldTransaction] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [request, setRequest] = useState();
  const [sumData, setSumData] = useState(false);
  const [pageNumber, setPageNumber] = useState("");

  // const [tabQueryreset, setTabQueryreset] = useState(true)
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [typeList, setTypeList] = useState([]);
  const navigate = useNavigate();
  const [resetFilter, setResetFilter] = useState(false);
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState();
  const [tabQueryreset, setTabQueryreset] = useState(false);
  const [refreshTab, setRefreshTab] = useState(0);
  // const chooseCat = (value) => {
  //   setQuery("&category=" + value);
  // };
  // const [columnOptions, setColumnOptions] = useState([]);
  const location = useLocation();
  const status = location.state?.status.toLowerCase();
  console.log("the status is in retddtransaction is ", status);

  const isFilterAllowed = useMemo(
    () =>
      user?.role.toLowerCase() === "admin" ||
      user?.role.toLowerCase() === "dd" ||
      user?.role.toLowerCase() === "ad" ||
      user?.role.toLowerCase() === "asm" ||
      user?.role.toLowerCase() === "ret" ||
      user?.role.toLowerCase() === "api",
    [user]
  );

  const getExcel = () => {
    get(
      showOldTransaction && showOldTransaction
        ? ApiEndpoints.OLD_TRANSACTIONS
        : ApiEndpoints.GET_TRANSACTIONS,
      // ApiEndpoints.GET_USERS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };
  const handlePageNavigation = () => {
    if (pageNumber && !isNaN(pageNumber)) {
      const newQuery = `${query ? query + "&" : ""}page=${pageNumber}`;
      setQuery(newQuery);
      refresh(); // Trigger data refresh (if applicable in your setup)
    } else {
      const newQuery = `${query ? query + "&" : ""}page=${1}`;
      setQuery(newQuery);
      refresh();
    }
  };
  const getCsv = () => {
    get(
      showOldTransaction && showOldTransaction
        ? ApiEndpoints.OLD_TRANSACTIONS
        : ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  // get types
  const getTypes = () => {
    setTypeList(Categories);
    // if (typeList.length === 0) {
    //   get(
    //     ApiEndpoints.GET_CATEGORIES,
    //     "",
    //     setRequest,
    //     (res) => {
    //       const data = res.data.data;

    //       setTypeList(data);
    //     },
    //     (err) => {
    //       apiErrorToast(err);
    //     }
    //   );
    // }
  };
  // const getTypes = () => {
  //   if (typeList.length === 0) {
  //     get(
  //       ApiEndpoints.GET_CATEGORIES,
  //       "",
  //       setRequest,
  //       (res) => {
  //         const data = res.data.data;
  //         if (data) {
  //           data.push({ id: 13, name: "ALL" });
  //         }
  //         setTypeList(data);
  //       },
  //       (err) => {
  //         apiErrorToast(err);
  //       }
  //     );
  //   }
  // };
  useEffect(() => {
    refreshUser();
    getTypes();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (status && status !== "total") {
      setQuery((prevQuery) => {
        const queryParams = new URLSearchParams(prevQuery || "");
        queryParams.set("status", status);
        return queryParams.toString();
      });
    }
  }, [status]);

  // useEffect(() => {

  //   if (status) {
  //     const queryString = `page=1&paginate=10&export=1&status=${status}`;
  //     setQuery(queryString);
  //   } else {
  //     setQuery('page=1&paginate=10&export=1');
  //   }
  // }, [status]);
  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const {
    setChooseInitialCategoryFilter,
    chooseInitialCategoryFilter,
    refreshUser,
  } = useCommonContext();

  const prefilledQuery = `category=${chooseInitialCategoryFilter}`;

  useEffect(() => {
    if (chooseInitialCategoryFilter && chooseInitialCategoryFilter !== "ALL") {
      setQuery(`category=${chooseInitialCategoryFilter}`);
    }
  }, [chooseInitialCategoryFilter]);

  function refreshFunc(setQueryParams) {
    if (refresh) refresh();
  }

  const [operatorList, setOperatorList] = useState([]);
  const getOperatorVal = () => {
    get(
      ApiEndpoints.GET_OPERATOR,
      "",
      "",
      (res) => {
        const opArray = res.data.data;
        // const op = opArray.map((item) => {
        //   return {
        //     code: item.code,
        //     name: item.name,
        //   };
        // });
        setOperatorList(opArray);
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {}
  };

  // table data & conditional styles.....
  const conditionalRowStyles = [
    {
      when: (row) => row.operator.toLowerCase() === "admin transfer",
      style: {
        backgroundColor: "#dcf5e7",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  ////  disable rows
  const isStatusPending = (row) =>
    // row.status.toLowerCase() === "pending" ||
    row.status.toLowerCase() === "refund" ||
    row.status.toLowerCase() === "failed" ||
    row.operator === "Vendor Payments";

  const columns = [
    {
      name: "Date/Time",
      selector: (row) => (
        <div className="mb-1" style={{ textAlign: "left" }}>
          {ddmmyy(row.created_at)} {dateToTime(row.created_at)}
        </div>
      ),
      wrap: true,
    },

    {
      name: <span className="">Operator</span>,
      selector: (row) => (
        <div style={{ fontsize: "11px", textAlign: "left" }}>
          <Tooltip
            placement="bottom"
            title={
              row.operator === "Vendor Payments" ? "settlements" : row.operator
            }
          >
            <div>
              {row.operator === "Vendor Payments"
                ? "settlements"
                : row.operator}
            </div>
          </Tooltip>
        </div>
      ),
      wrap: true,
      width: "115px",
    },
    // est missing from ad login

    {
      name: <span className="">Number</span>,
      selector: (row) => (
        <div className="d-flex flex-column align-items-start">
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "500",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              copyToClipBoard(row.number);
              handleClickSnack();
            }}
          >
            {row.number}
          </Typography>
        </div>
      ),
      wrap: true,
      center: false,
    },
    {
      name: <span className="ms-3">Info</span>,
      selector: (row) => (
        <Typography
          className="d-flex flex-column align-items-start ms-3"
          sx={{
            fontSize: "12px",
            textAlign: "left",
            color: "	#171910",
            fontWeight: "500",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            copyToClipBoard(row.ben_acc);
            handleClickSnack();
          }}
        >
          <div>{row.ben_name}</div>
          <div style={{ fontSize: "12px", fontWeight: "600" }}>
            {row.ben_acc}
          </div>
          <div style={{ fontWeight: "600", color: "#808080" }}>{row.ifsc}</div>
        </Typography>
      ),
      wrap: true,
      width: "140px",
      omit: role === "ad",
    },
    {
      name: "Operator Id",
      selector: (row) => (
        <Tooltip title={row.op_id} placement="top">
          <div
            className="break-words"
            style={{
              fontSize: "inherit",
              fontWeight: "500",
              textAlign: "left",
              color: "	#171910",
              fontFamily: "sans-serif",
            }}
          >
            {row.op_id}
          </div>
        </Tooltip>
      ),

      width: "140px",
      wrap: true,
    },

    {
      name: <span className="pe-2">Amount</span>,
      cell: (row) => (
        <div className="d-flex flex-column align-items-end pe-3 fw-bold">
          <div style={{ color: "green", textAlign: "left" }}>
            {currencySetter(row.amount)}
          </div>

          <div
            style={{ color: "#5B4C7D", textAlign: "left" }}
            hidden={role !== "admin"}
          >
            {currencySetter(row.ad_comm)}
          </div>
        </div>
      ),
      right: true,
    },
    {
      name: "Charge",
      cell: (row) => (
        <div style={{ color: "red", textAlign: "left" }} className="fw-bold">
          {currencySetter(row.ret_charge)}
        </div>
      ),

      center: true,
    },
    {
      name: "GST",
      cell: (row) => (
        <div style={{ color: "red", textAlign: "left" }} className="fw-bold">
          {currencySetter(row.gst)}
        </div>
      ),

      center: true,
    },

    {
      name: <span className="pe-2">Comm</span>,
      cell: (row) => (
        <div className="fw-bold">
          <div style={{ textAlign: "left" }}>
            {currencySetter(role === "ad" ? row.ad_comm : row.ret_comm)}
          </div>
        </div>
      ),
    },
    {
      name: "TDS",
      selector: (row) => (
        <>
          <div
            style={{ color: "#715E93", textAlign: "left" }}
            className="fw-bold"
          >
            {currencySetter(role === "ad" ? row.ad_tds : row.ret_tds)}
          </div>
        </>
      ),
      // omit: user && user.role !== "Ad",
    },

    {
      name: "CR/DR",
      selector: (row) => (
        <>
          <div
            style={{
              color: row.txn_type === "CR" ? "green" : "red",
              textAlign: "left",
              marginRight: "14px",
            }}
            className="fw-bold"
          >
            {row.txn_type === "CR" ? "+ " : "- "}
            {currencySetter(row.net_amount)}
          </div>
        </>
      ),
      width: "auto",
      left: true,
      omit: user && user.role === "Ad",
    },
    {
      name: <span className="pe-2">GST/TDS</span>,
      cell: (row) => (
        <div className="fw-bold">
          <div>{currencySetter(row.gst)}</div>
          <div>{currencySetter(row.ret_tds)}</div>
        </div>
      ),
      omit: role !== "admin",
    },
    {
      name: "Closing",
      selector: (row) => (
        <div className="d-flex align-items-start flex-column fw-bold">
          <div>{currencySetter(row.w1)}</div>
          <div style={{ color: "#F29132" }}>{currencySetter(row.w2)}</div>
        </div>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <CommonStatus
              status={row.status}
              approvedStatusText="Success"
              pendingStatusText="Pending"
              rejectedStatusText="Failed"
              refundStatusText="Refund"
              fontSize="13px"
            />
          </Box>
        </>
      ),
    },

    {
      name: "Details",
      selector: (row) => (
        <Tooltip title="View">
          {(user.role === "Ret" || user.role === "Dd") && (
            <RightSidePannel row={row} />
          )}
        </Tooltip>
      ),
      center: true,
      width: "70px",
    },
  ];

  // const searchOptions = [
  //   { field: "Number", parameter: "number" },
  //   { field: "Account", parameter: "ben_acc" },
  //   { field: "Username", parameter: "username" },
  // ];
  console.log("====================================");
  console.log("selectedRows", selectedRows);
  console.log("====================================");
  const statusList = [
    { name: "SUCCESS", code: "SUCCESS" },
    { name: "PENDING", code: "PENDING" },
    { name: "REFUND", code: "REFUND" },
    { name: "FAILED", code: "FAILED" },
  ];
  useEffect(() => {
    if (selectedRows) {
      localStorage.setItem(
        "selectedRow",
        JSON.stringify(selectedRows.selectedRows)
      );
    }
    return () => {};
  }, [selectedRows]);
  const tabs = [
    { label: "ALL" },
    { label: "Money Transfer" },

    { label: "Collections" },
    { label: "Admin Transfer" },
    { label: "Utility" },
    { label: "verification" },
    { label: "Recharge" },
    { label: "Irctc" },
  ];

  if (!chooseInitialCategoryFilter && role !== "admin" && role !== "api") {
    return (
      <>
        {/* <CustomTabs
      tabs={tabs}
      value={value}
      onChange={handleChange}

    /> */}
        {user?.aggreement === 1 ? (
          <RetDbTransactionTab
            setQuery={setQuery}
            setRefreshTab={setRefreshTab}
            refreshTab={refreshTab}
          />
        ) : (
          ""
        )}

        {/* <Grid container>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              textAlign: "left",
              pl: 3,
              fontSize: "25px",
              fontWeight: "600",
              mb: 3,
            }}
          >
            Reports
          </Grid>
          {typeList.length > 1 &&
            typeList
              .filter((i) => i.name !== "SECONDARY")
              .map((item) => {
                return (
                  <Grid
                    item
                    md={2}
                    onClick={() => {
                      setChooseInitialCategoryFilter(item.name);
                    }}
                    className="all-categories-box hover-less-zoom"
                  >
                    <Box sx={{ width: "30%", p: 1 }}>
                      <Box className="transaction-icon-box">
                        <img
                          src={`https://api.impsguru.com/public/logos/${
                            item.name.split(" ")[0]
                          }.svg`}
                          alt="categories"
                          width="65%"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: "70%" }}>{item.name}</Box>
                  </Grid>
                );
              })}
        </Grid> */}
        {user?.aggreement === 1 ? (
          <>
            <Grid container>
              {/* small screen action button and filter modal show only on small screen*/}

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  justifyContent: "end",
                  alignItems: "center",
                  flexDirection: { md: "row" },
                  pr: 1,
                }}
              >
                {/* back button */}
                <div className="me-3">
                  <Button
                    size="small"
                    className="otp-hover-purple"
                    sx={{
                      color: primaryColor(),
                    }}
                    onClick={() => {
                      setChooseInitialCategoryFilter(false);
                    }}
                  >
                    <KeyboardBackspaceIcon fontSize="small" /> Back
                  </Button>
                </div>{" "}
                {/* radio toggle */}
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      mt: { md: 0, sm: 2, xs: 2 },
                      mb: { md: 0, sm: 2, xs: 2 },
                    }}
                    control={
                      <Switch
                        value={showOldTransaction}
                        defaultChecked={showOldTransaction}
                        onChange={() =>
                          setShowOldTransaction(!showOldTransaction)
                        }
                      />
                    }
                    label={
                      <Typography variant="body2" style={{ fontSize: "15px" }}>
                        Old
                      </Typography>
                    }
                  />
                </FormGroup>
                {/* excel */}
                <div className="mx-2">
                  <ExcelUploadModal
                    twobuttons="Download Csv"
                    btn
                    request={request}
                    getExcel={getExcel}
                    getCsv={getCsv}
                    noOfResponses={noOfResponses}
                    setQuery={setQuery}
                    handleCloseCB={(closeModal) => {
                      handleCloseModal = closeModal;
                    }}
                  />
                </div>
                {/* refresh */}
                <div className="me-3">
                  <RefreshComponent
                    className="refresh-icon-table"
                    onClick={() => {
                      refreshFunc(setQuery);
                    }}
                  />
                </div>
                <FilterModal
                  ifoperatorFilter
                  ifstatusFilter
                  ifdateFilter
                  // type and category is sa
                  ifTypeFilter
                  ifnumberFilter
                  ifotherFilter
                  typeList={typeList.filter((item) => item.name !== "ALL")}
                  operatorList={operatorList}
                  statusList={statusList}
                  getOperatorVal={getOperatorVal}
                  setQuery={setQuery}
                  query={query}
                  clearHookCb={(cb) => {
                    refreshFilter = cb;
                  }}
                  getTypes={getTypes}
                  refresh={refresh}
                />{" "}
              </Grid>

              {/* <RightSidePannel
          state={state}
          setState={setState}
          row={rowData}
          buttons={
            (rowData?.status === "SUCCESS" ||
              rowData?.status === "PENDING") && (
              <RaiseIssueModal row={rowData} refresh={refresh} />
            )
          }
        /> */}
            </Grid>
            <Grid xs={12} sx={{ pl: { xs: 0, md: 0 } }}>
              <ApiPaginateSearch
                totalCard={
                  <>
                    <StatusDisplay sumData={sumData} setSumData={setSumData} />
                  </>
                }
                setSumData={setSumData}
                actionButtons={
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: { md: "none", sm: "none", xs: "flex" },
                      justifyContent: "end",
                      alignItems: "center",
                      flexDirection: { md: "row" },
                      pr: 1,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          mt: { md: 0, sm: 2, xs: 2 },
                          mb: { md: 0, sm: 2, xs: 2 },
                        }}
                        control={
                          <Switch
                            value={showOldTransaction}
                            defaultChecked={showOldTransaction}
                            onChange={() =>
                              setShowOldTransaction(!showOldTransaction)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontSize: "15px" }}
                          >
                            Old
                          </Typography>
                        }
                      />
                    </FormGroup>
                    {user?.role?.toLowerCase() !== "admin" &&
                      user?.role?.toLowerCase() !== "asm" &&
                      user?.role?.toLowerCase() !== "ad" &&
                      user?.role?.toLowerCase() !== "api" && (
                        <Tooltip title="Download Receipt" placement="top">
                          <PrintIcon
                            color="success"
                            className=" mx-2 refresh-purple"
                            onClick={() => {
                              if (selectedRows?.selectedRows?.length > 0) {
                                const selectedType =
                                  selectedRows.selectedRows[0].type; // Access the type of the first selected row

                                if (selectedType === "PREPAID") {
                                  window.open("/receipt", "_blank");
                                } else if (selectedType === "MONEY TRANSFER") {
                                  window.open("/dmt-receipt", "_blank");
                                } else {
                                  apiErrorToast("Invalid Transaction Type.");
                                }
                              } else {
                                apiErrorToast("No Transaction Selected.");
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    <div className="mx-2">
                      <ExcelUploadModal
                        twobuttons="Download Csv"
                        btn
                        request={request}
                        getExcel={getExcel}
                        getCsv={getCsv}
                        noOfResponses={noOfResponses}
                        setQuery={setQuery}
                        handleCloseCB={(closeModal) => {
                          handleCloseModal = closeModal;
                        }}
                      />
                    </div>
                    <RefreshComponent
                      onClick={() => {
                        refreshFunc(setQuery);
                      }}
                    />
                  </Grid>
                }
                backButton={
                  role !== "admin" && role !== "api"
                    ? // <Button
                      //   size="small"
                      //   className="otp-hover-purple mb-2"
                      //   sx={{
                      //     color: primaryColor(),
                      //   }}
                      //   onClick={() => {
                      //     setChooseInitialCategoryFilter(false);
                      //   }}
                      // >
                      //   <KeyboardBackspaceIcon fontSize="small" /> Back
                      // </Button>
                      ""
                    : false
                }
                apiEnd={
                  showOldTransaction && showOldTransaction
                    ? ApiEndpoints.OLD_TRANSACTIONS
                    : ApiEndpoints.GET_TRANSACTIONS
                }
                searchOptions={searchOptions[`${role}`]}
                setQuery={setQuery}
                columns={columns}
                apiData={apiData}
                setApiData={setApiData}
                tableStyle={CustomStyles}
                setResetFilter={setResetFilter}
                queryParam={query ? query : ""}
                setTabQueryreset={setTabQueryreset}
                returnRefetch={(ref) => {
                  refresh = ref;
                }}
                conditionalRowStyles={conditionalRowStyles}
                selectableRows
                selectableRowDisabled={isStatusPending}
                onSelectedRowsChange={(data) => {
                  setSelectedRows(data);
                }}
                responses={(val) => {
                  setNoOfResponses(val);
                }}
                isFilterAllowed={isFilterAllowed}
                filterComponent={
                  <FilterCard
                    showSearch={false}
                    ifoperatorFilter
                    ifstatusFilter
                    setRefreshTab={setRefreshTab}
                    setTabQueryreset={setTabQueryreset}
                    ifdateFilter
                    // type and category is same
                    ifTypeFilter
                    chooseInitialCategoryFilter={
                      chooseInitialCategoryFilter !== "ALL"
                        ? chooseInitialCategoryFilter
                        : false
                    }
                    //
                    tabQueryreset={tabQueryreset}
                    ifnumberFilter
                    ifotherFilter
                    setResetFilter={setResetFilter}
                    typeList={typeList.filter((item) => item.name !== "ALL")}
                    nonAdminColOptions={nonAdminColOptions[`${role}`]}
                    statusList={statusList}
                    operatorList={operatorList}
                    getOperatorVal={getOperatorVal}
                    setQuery={setQuery}
                    query={query}
                    clearHookCb={(cb) => {
                      refreshFilter = cb;
                    }}
                    refresh={refresh}
                    getTypes={getTypes}
                    isShowFilterCard={isShowFilterCard}
                    setIsShowFilterCard={setIsShowFilterCard}
                    // button
                    // backButton={
                    //   <Button
                    //     size="small"
                    //     className="otp-hover-purple"
                    //     sx={{
                    //       color: primaryColor(),
                    //       mt: 2,
                    //     }}
                    //     onClick={() => {
                    //       setChooseInitialCategoryFilter(false);
                    //     }}
                    //   >
                    //     <KeyboardBackspaceIcon fontSize="small" /> Back
                    //   </Button>
                    // }
                    actionButtons={
                      <>
                        <FormGroup>
                          <FormControlLabel
                            // sx={{
                            //   mt: { md: 2, sm: 2, xs: 2 },
                            //   mb: { md: 0, sm: 2, xs: 2 },
                            // }}
                            control={
                              <Switch
                                value={showOldTransaction}
                                defaultChecked={showOldTransaction}
                                onChange={() =>
                                  setShowOldTransaction(!showOldTransaction)
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontSize: "15px" }}
                              >
                                Old
                              </Typography>
                            }
                          />
                        </FormGroup>

                        <Tooltip title="Download Receipt" placement="top">
                          <PrintIcon
                            color="success"
                            // sx={{mt:2}}
                            className="refresh-purple"
                            onClick={() => {
                              if (selectedRows?.selectedRows?.length > 0) {
                                const selectedType =
                                  selectedRows.selectedRows[0].type; // Access the type of the first selected row

                                if (
                                  selectedType === "PREPAID" ||
                                  selectedType === "DTH"
                                ) {
                                  window.open("/receipt", "_blank");
                                } else if (
                                  selectedType === "MONEY TRANSFER" ||
                                  selectedType === "PAYMENTS"
                                ) {
                                  window.open("/dmt-receipt", "_blank");
                                } else if (selectedType === "UTILITY") {
                                  window.open("/utility-receipt", "_blank");
                                } else if (selectedType === "COLLECTIONS") {
                                  window.open("/aeps-receipt", "_blank");
                                } else {
                                  apiErrorToast("Invalid Transaction Type.");
                                }
                              } else {
                                apiErrorToast("No Transaction Selected.");
                              }
                            }}
                          />
                        </Tooltip>

                        <div className="me-1">
                          <ExcelUploadModal
                            twobuttons="Download Csv"
                            btn
                            request={request}
                            getExcel={getExcel}
                            getCsv={getCsv}
                            noOfResponses={noOfResponses}
                            setQuery={setQuery}
                            handleCloseCB={(closeModal) => {
                              handleCloseModal = closeModal;
                            }}
                          />
                        </div>
                        <Tooltip title="refresh">
                          <IconButton
                            aria-label="refresh"
                            // color="success"
                            sx={{
                              color: "#0F52BA",
                              ml: -1,
                            }}
                            onClick={() => {
                              refreshFunc(setQuery);
                            }}
                          >
                            <CachedIcon className="refresh-purple" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                }
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginBottom: 2,
                marginLeft: 3,
              }}
            >
              <TextField
                label="Page Number"
                variant="outlined"
                size="small"
                value={pageNumber}
                onChange={(e) => setPageNumber(e.target.value)}
                sx={{ width: "100px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handlePageNavigation}
              >
                Go
              </Button>
            </Box>
          </>
        ) : (
          <Box>
            {/* <Grid
                  item
                  className="card-css"
                  sx={{ p: 3, mt: 2, mb: { md: 0, xs: 5 } }}
                >
                  <h5>To use our services, please sign the agreement</h5>
                  <br />
                  {user.role === "Ret" || user.role === "Dd" ? (
                    <a href="/service" target="_blank" rel="noopener noreferrer">
                      Click here
                    </a>
                  ) : (
                    <a
                      href="/distributoragreement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>
                  )}
                </Grid> */}
            <Grid
              item
              className="card-css"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                p: 4,

                position: "absolute",
                top: "50%",
                left: "40%",
                transform: "translate(-50%, -50%)",

                backgroundColor: " rgb(73, 132, 200) ",
                borderRadius: "10px",
                boxShadow: 3,
                maxWidth: "600px",

                height: "300px",
              }}
            >
              <Box sx={{ textAlign: "center", color: "#fff", width: "100%" }}>
                <h5
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "20px",
                    letterSpacing: "1px",
                  }}
                >
                  To use our services, please sign the agreement
                </h5>

                <br />
                {user.role === "Ret" || user.role === "Dd" ? (
                  <a
                    href="/service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff", // White color for the link
                      fontSize: "18px", // Slightly larger font for the link
                      fontWeight: "500", // Medium weight for the link
                      textDecoration: "none", // No underline by default
                      backgroundColor: "rgb(220, 42, 113)", // Dark blue background for a button effect
                      padding: "8px 20px", // Padding inside the button
                      borderRadius: "30px", // Rounded button shape
                      display: "inline-block",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "rgb(26, 32, 38)"; // Darken blue on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "rgb(38, 50, 56)"; // Revert to original color
                    }}
                  >
                    Click here
                  </a>
                ) : (
                  <a
                    href="/distributoragreement"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff", // White color for the link
                      fontSize: "18px", // Slightly larger font for the link
                      fontWeight: "500", // Medium weight for the link
                      textDecoration: "none", // No underline by default
                      backgroundColor: "rgb(148, 135, 22)", // Light yellow background for a button effect
                      padding: "8px 20px", // Padding inside the button
                      borderRadius: "30px", // Rounded button shape
                      display: "inline-block", // Inline-block to maintain button structure
                      transition: "background-color 0.3s", // Smooth background color change
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "rgb(255, 193, 7)"; // Darken yellow on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "rgb(255, 235, 59)"; // Revert to original color
                    }}
                  >
                    Click here
                  </a>
                )}
              </Box>
            </Grid>
          </Box>
        )}
        {/* <Grid
          container
          sx={{ mt: 5 }}
          hidden={role !== "ret" && role !== "dd" && role !== "ad"}
        >
          {(role === "ad"
            ? AD_REPORTS
            : REPORTS.filter((i) => {
                if (user?.acst === 0) {
                  return i.name !== "ACCOUNT LEDGER";
                } else {
                  return i;
                }
              })
          ).map((item) => {
            return (
              <Grid
                item
                md={2}
                onClick={() => navigate(item.url)}
                className="all-categories-box hover-less-zoom"
                // sx={{
                //   backgroundImage: `url("https://api.impsguru.com/public/logos/${
                //     item.name.split(" ")[0]
                //   }.svg")`,
                // }}
              >
                <Box sx={{ width: "30%", p: 1 }}>
                  <Box className="transaction-icon-box">
                    <img
                      src={`https://api.impsguru.com/public/logos/${
                        item.name.split(" ")[0]
                      }.svg`}
                      alt="categories"
                      width="65%"
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "70%" }}>{item.name}</Box>
              </Grid>
            );
          })}
        </Grid> */}
      </>
    );
  } else {
    return (
      <Grid container>
        {/* small screen action button and filter modal show only on small screen*/}

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: { md: "none", sm: "none", xs: "flex" },
            justifyContent: "end",
            alignItems: "center",
            flexDirection: { md: "row" },
            pr: 1,
          }}
        >
          {/* back button */}
          <div className="me-3">
            <Button
              size="small"
              className="otp-hover-purple"
              sx={{
                color: primaryColor(),
              }}
              onClick={() => {
                setChooseInitialCategoryFilter(false);
              }}
            >
              <KeyboardBackspaceIcon fontSize="small" /> Back
            </Button>
          </div>{" "}
          {/* radio toggle */}
          <FormGroup>
            <FormControlLabel
              sx={{
                mt: { md: 0, sm: 2, xs: 2 },
                mb: { md: 0, sm: 2, xs: 2 },
              }}
              control={
                <Switch
                  value={showOldTransaction}
                  defaultChecked={showOldTransaction}
                  onChange={() => setShowOldTransaction(!showOldTransaction)}
                />
              }
              label={
                <Typography variant="body2" style={{ fontSize: "15px" }}>
                  Old
                </Typography>
              }
            />
          </FormGroup>
          {/* excel */}
          <div className="mx-2">
            <ExcelUploadModal
              twobuttons="Download Csv"
              btn
              request={request}
              getExcel={getExcel}
              getCsv={getCsv}
              noOfResponses={noOfResponses}
              setQuery={setQuery}
              handleCloseCB={(closeModal) => {
                handleCloseModal = closeModal;
              }}
            />
          </div>
          {/* refresh */}
          <div className="me-3">
            <RefreshComponent
              className="refresh-icon-table"
              onClick={() => {
                refreshFunc(setQuery);
              }}
            />
          </div>
          <FilterModal
            ifoperatorFilter
            ifstatusFilter
            ifdateFilter
            // type and category is sa
            ifTypeFilter
            ifnumberFilter
            ifotherFilter
            typeList={typeList.filter((item) => item.name !== "ALL")}
            operatorList={operatorList}
            statusList={statusList}
            getOperatorVal={getOperatorVal}
            setQuery={setQuery}
            query={query}
            clearHookCb={(cb) => {
              refreshFilter = cb;
            }}
            getTypes={getTypes}
            refresh={refresh}
          />{" "}
        </Grid>

        <RightSidePannel
          state={state}
          setState={setState}
          row={rowData}
          buttons={
            (rowData?.status === "SUCCESS" ||
              rowData?.status === "PENDING") && (
              <RaiseIssueModal row={rowData} refresh={refresh} />
            )
          }
        />
      </Grid>
    );
  }
};

export default RetDdTransactionView;
