import * as React from "react";
import Box from "@mui/material/Box";
import { IconButton, Tooltip, Modal } from "@mui/material";
import { Icon } from "@iconify/react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ApiEndpoints, { BASE_URL } from "../network/ApiEndPoints";
import { postJsonData } from "../network/ApiController";
import { apiErrorToast } from "../utils/ToastUtil";
import ModalFooter from "../modals/ModalFooter";
import ModalHeader from "../modals/ModalHeader";
import { useState } from "react";
import axios from "axios";
import AuthContext from "../store/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ViewModal = ({ row, refresh }) => {
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [imageUrl, setImageUrl] = useState(null);
  const authCtx = React.useContext(AuthContext);
  const token = authCtx.token;

  const handleOpen = () => {
    setOpen(true);
    fetchImage();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestImage = row?.request_image || "defaultFileName";
  const getImage = async (fileName) => {
    const headers = {
      Authorization: `Bearer ${token}`, 
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/${ApiEndpoints.GET_FILES}`,
        { fileName },
        { responseType: "blob", headers: headers }
      );

      const imageUrl = URL.createObjectURL(new Blob([response.data]));
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  const fetchImage = async () => {
    const fileName = requestImage;
    const url = await getImage(fileName);
    if (url) setImageUrl(url);
  };

  const handleZoomIn = () => {
    setScale((scale) => scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((scale) => scale - 0.1);
  };

  return (
    <>
      <Tooltip title="Reciept View">
        <IconButton
          onClick={handleOpen}
          sx={{
            color: "#5234ea",
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              position: "relative", // Ensure that the zoom buttons are positioned relative to this container
            }}
          >
            <ModalHeader title="Reciept View" handleClose={handleClose} />
            <Box sx={{ textAlign: "center" }}>
              <div>
                {imageUrl && (
                  <img
                    style={{
                      width: "30%",
                      transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                    }}
                    src={imageUrl}
                    alt="Fetched Image"
                  />
                )}
              </div>
            </Box>

            {/* Buttons positioned at the bottom-right corner */}
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                right: 10,
                display: "flex",
                gap: "10px", // Space between the buttons
              }}
            >
               <Tooltip title="Zoom Out">
              <IconButton onClick={handleZoomOut}>
                <RemoveIcon />
              </IconButton>
              </Tooltip>
                    <Tooltip title="Zoom In">
              <IconButton onClick={handleZoomIn}>
                <AddIcon />
              </IconButton>
              </Tooltip>
             
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ViewModal;
