import React, { useState } from "react";
import { useForm, Controller, getValues } from "react-hook-form";
import { TextField, Button, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";

const TwoStepForm = () => {
  const { control, handleSubmit, reset, setValue, getValues } = useForm();
  const [showOtpField, setShowOtpField] = useState(false);
  const [mobileData, setMobileData] = useState({ old: "", new: "" });

  const onSubmitFirst = (data) => {
    console.log("First Submission Data:", data);
    setMobileData(data);

    postJsonData(
      ApiEndpoints.PAN_LINK,
      { ...data },
      null,
      (res) => {
        okSuccessToast("Otp Sent To Both Numbers");
        setShowOtpField(true);
      },
      (err) => {
     
        apiErrorToast(err.response?.data?.data?.status || "Error occurred");
      }
    );
  };

  const onResendOtp = () => {
    const { old, new: newMobile } = getValues(); // Fetch form values directly

    if (!old || !newMobile) {
      apiErrorToast("Please enter mobile numbers first");
      return;
    }

    postJsonData(
      ApiEndpoints.PAN_LINK,
      { old, new: newMobile },
      null,
      (res) => {
        okSuccessToast("OTP resent to both numbers");
      },
      (err) => {
        apiErrorToast(err.response?.data?.data?.status || "Error occurred");
      }
    );
  };

  const onSubmitOtp = (data) => {
    console.log("OTP Submission Data:", { ...data, ...mobileData });

    postJsonData(
      ApiEndpoints.PAN_VERIFY,
      { ...data, ...mobileData },
      null,
      (res) => {
        okSuccessToast(res.data.status);
        reset();
        setShowOtpField(false);
      },
      (err) => {
        apiErrorToast(err.response?.data?.data?.status || "Error occurred");
      }
    );
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 3,
        p: 3,
        border: "1px solid #ccc",
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        {showOtpField ? "Enter OTP" : "Change PAN Mobile"}
      </Typography>

      {showOtpField && (
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => {
            setShowOtpField(false);
            reset();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <form onSubmit={handleSubmit(showOtpField ? onSubmitOtp : onSubmitFirst)}>
        {/* Always show mobile number fields */}
        <Controller
          name="old"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Old Mobile Number"
              fullWidth
              margin="normal"
              required
              inputProps={{ maxLength: 10, pattern: "[0-9]{10}" }}
              disabled={showOtpField}
            />
          )}
        />
        {showOtpField && (
          <Controller
            name="oldOtp"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Enter Old OTP"
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 6, pattern: "[0-9]{6}" }}
              />
            )}
          />
        )}
        <Controller
          name="new"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="New Mobile Number"
              fullWidth
              margin="normal"
              required
              inputProps={{ maxLength: 10, pattern: "[0-9]{10}" }}
              disabled={showOtpField}
            />
          )}
        />

        {showOtpField && (
          <Controller
            name="newOtp"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Enter New OTP"
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 6, pattern: "[0-9]{6}" }}
              />
            )}
          />
        )}
{showOtpField && (
  <Box display="flex" justifyContent="flex-end">
    <Button
      onClick={onResendOtp}
      variant="contained"
      sx={{ mt: 1, minWidth: "80px", fontSize: "12px", padding: "4px 8px" }}
    >
      Resend OTP
    </Button>
  </Box>
)}

<Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
  {showOtpField ? "Submit OTP" : "Submit"}
</Button>

      </form>
    </Box>
  );
};

export default TwoStepForm;
