import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FormControl, Grid, TextField, Button, Select, MenuItem, InputLabel, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { useState, useEffect } from "react";
import { postJsonData } from "../network/ApiController";
import useCommonContext from "../store/CommonContext";
import Loader from "../component/loading-screen/Loader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel"; // Import CancelIcon
import { PaymentMethods } from "../utils/constants";

const AddBeneficiaryUpiModal = ({ rem_mobile, apiEnd, getRemitterStatus }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const [prefix, setPrefix] = useState(""); // State for prefix
  const [vpaDomain, setVpaDomain] = useState("****"); // Default VPA domain
  const [manualDomain, setManualDomain] = useState(""); // Manual domain input
  const [upiValue, setUpiValue] = useState(""); // Final UPI address

  const { getRecentData } = useCommonContext();
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z\s]{3,}$/.test(value); // Allows only letters & spaces, min length 3
    setError(!isValid);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: "max-content",
    overflowY: "scroll",
    p: 2,
  };

  // Handle modal open/close
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPrefix("");
    setManualDomain("");
    setVpaDomain("");
    setUpiValue("");
    setError("");
  };

  // Handle Prefix Change
  const handlePrefixChange = (event) => setPrefix(event.target.value);

  // Handle Suffix Selection
  const handleSetVpa = (event) => {
    setVpaDomain(event.target.value);
    if (event.target.value !== "Other") {
      setManualDomain(""); // Reset manual domain if another option is selected
    }
  };

  // Reset manual domain field
  const resetManualDomain = () => {
    setVpaDomain("");
    setManualDomain("");
  };

  // Update UPI address dynamically
  useEffect(() => {
    const suffix = vpaDomain === "Other" && manualDomain ? manualDomain : vpaDomain;
    if (prefix && suffix) {
      setUpiValue(`${prefix}@${suffix}`); // Ensure '@' is properly included
    } else {
      setUpiValue(""); // Reset if empty
    }
  }, [prefix, vpaDomain, manualDomain]);
  

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      name: event.currentTarget.name.value,
      rem_number: rem_mobile,
      vpa: upiValue,
    };

    postJsonData(
      apiEnd,
      data,
      setRequest,
      (res) => {
        getRecentData();
        okSuccessToast("Beneficiary Added Successfully");
        handleClose();
        if (getRemitterStatus) getRemitterStatus(rem_mobile);
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Button
        variant="text"
        onClick={handleOpen}
        size="small"
       className="refresh-icon-risk"
      >
        <AddCircleIcon sx={{ mr: 1, fontSize: "16px", mb: 0.5 }} />
        Add Beneficiary
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="sm_modal">
          <Loader loading={request} />
          <ModalHeader title="Add Beneficiary" handleClose={handleClose} />
          <Box
            component="form"
            id="addbene"
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{ "& .MuiTextField-root": { m: 1, height: "45px" }, "& .MuiFormControl-root": { mb: 2 } }}
          >
            <Grid container sx={{ pt: 1 }}>
              <Grid item md={12} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                <TextField
                    autoComplete="off"
                    label="Name"
                    id="name"
                    size="small"
                    required
                    inputProps={{ minLength: 3 }}
                    helperText={
                      error
                        ? "Enter at least 3 letters (only alphabets allowed)"
                        : ""
                    }
                    error={error}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z\s]/g,
                        ""
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12} container spacing={2}>
                <Grid item md={5.6} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField autoComplete="off" label="Prefix" size="small" required value={prefix} onChange={handlePrefixChange} sx={{ height: "45px" }} />
                  </FormControl>
                </Grid>
                <Typography sx={{ mt: { md: 4, sm: -3 }, ml: { md: 0.5, sm: 6 }, textAlign: { xs: "center", sm: "center", md: "left" } }}>@</Typography>
                
                {vpaDomain !== "Other" && (
                  <Grid item md={5.7} xs={12}>
                    <FormControl sx={{ width: "100%", mt: 1 }}>
                      <InputLabel>Suffix</InputLabel>
                      <Select value={vpaDomain} onChange={handleSetVpa} label="VPA Domain" required sx={{ height: "40px" }}>
                        {PaymentMethods.map((method, index) => (
                          <MenuItem key={index} value={method.value}>{method.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {vpaDomain === "Other" && (
                  <Grid item md={5.7} xs={12} sx={{ position: "relative" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField label="Enter VPA Domain" value={manualDomain} onChange={(e) => setManualDomain(e.target.value)} size="small" required sx={{ height: "45px", mt: 2 }} />
                    </FormControl>
                    <CancelIcon
                      sx={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer", fontSize: "20px" }}
                      onClick={resetManualDomain}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Display the UPI Address */}
              <Typography sx={{ mt: 2, fontWeight: "bold", textAlign: "center", color:upiValue?"green":"black" }}>
                UPI: {upiValue || "Enter details to generate UPI"}
              </Typography>
            </Grid>
          </Box>
          <ModalFooter form="addbene" request={request} btn="Add Beneficiary" />
        </Box>
      </Modal>
    </Box>
  );
};

export default AddBeneficiaryUpiModal;
