import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { postJsonData } from "../network/ApiController";
import { useState } from "react";
import PinInput from "react-pin-input";
import AuthContext from "../store/AuthContext";
import { useContext } from "react";
import ResetMpin from "./ResetMpin";
import useCommonContext from "../store/CommonContext";
import Loader from "../component/loading-screen/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { backgroundMT } from "../iconsImports";
const AccountVerificationModal = ({
  ben,
  rem_number,
  remitterStatus,
  getRemitterStatus,
  dmtValue,
  view = "",
}) => {
  // console.log("ben", ben);
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const [mpin, setMpin] = useState("");
  const [err, setErr] = useState();
  const { getRecentData } = useCommonContext();

  const authCtx = useContext(AuthContext);
  const loc = authCtx.location && authCtx.location;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: "max-content",
    // Padding for the modal box
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (mpin !== "") {
      const data = {
        number: rem_number && rem_number,
        ben_acc: ben.bene_acc
          ? ben.bene_acc
          : ben.account
          ? ben.account
          : ben.accno,
        ben_id: ben.id ? ben.id : ben.bene_id,
        ifsc: ben.ifsc,
        latitude: loc.lat,
        longitude: loc.long,
        ben_name: ben.bene_name && ben.bene_name ? ben.bene_name : ben.name,
        pf: "WEB",
        mpin: mpin,
        verified: dmtValue === "dmt2" ? 1 : undefined,
      };
      setRequest(true);
      postJsonData(
        ApiEndpoints.VERIFY_ACC,
        data,
        setRequest,
        (res) => {
          getRecentData();
          okSuccessToast(res.data.message);
          handleClose();
          if (remitterStatus) getRemitterStatus(rem_number);
        },
        (error) => {
          if (error && error) {
            if (error.response.data.message === "Invalid M Pin") {
              setErr(error.response.data);
            } else {
              getRecentData();
              setErr("");
              handleClose();
              apiErrorToast(error);
            }
            if (remitterStatus) getRemitterStatus(rem_number);
          }
        }
      );
    } else {
      setErr("");
      setMpin("");
      const error = {
        message: "MPIN required",
      };
      setErr(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Tooltip title="Verify Now">
        <Button
          // endIcon={<PriorityHighIcon />}
          className="button-verify-outline"
          sx={{
            fontSize: "13px",
            py: 0,
            ml: 1,
            px: 1,
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleOpen}
        >
          <Loader loading={request} size="small" />
          Verify
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Loader loading={request} />
          {/* <ModalHeader title="Verify Account" handleClose={handleClose} /> */}
          <Box
            component="form"
            id="expMoneyTransfer"
            validate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Grid
              container
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
                zIndex: 1,
                mt: 3,
                width: "70%",
                ml: "14%",
                mb: "25%",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",

                    bgcolor: "#f5f5f5",
                    padding: "16px",
                    boxShadow: 2,
                    position: "absolute",
                    borderRadius: 2,
                    height: "auto",
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>Name</td>
                      <td>:</td>
                      <td style={{ textAlign: "right" }}>
                        {ben.bene_name && ben.bene_name
                          ? ben.bene_name
                          : ben.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Bank Name </td> <td>:</td>
                      <td style={{ textAlign: "right" }}>
                        {ben.bank ? ben.bank : ben.bankname}
                      </td>
                    </tr>
                    <tr>
                      <td>Account </td>
                      <td>:</td>
                      <td style={{ textAlign: "right" }}>
                        {ben.bene_acc && ben.bene_acc
                          ? ben.bene_acc
                          : ben.account
                          ? ben.account
                          : ben.accno}
                      </td>
                    </tr>
                    <tr>
                      <td>IFSC </td>
                      <td>:</td>
                      <td style={{ textAlign: "right" }}>{ben.ifsc}</td>
                    </tr>
                  </table>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start", // Align items to the top
                mt: 7,
                width: "100%", // Use full width
                margin: "0 auto",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "260px",
                    bgcolor: "#ffffff",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",

                    zIndex: 0,
                    borderRadius: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundImage: `url(${backgroundMT})`, // Background image path
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 6,
                      }}
                    >
                      <FormControl>
                        <Typography
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontSize: { sm: "14px" },
                          }}
                        >
                          Enter M-PIN
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1, // Space between the M-PIN input and buttons
                            mt: 1, // Margin above the buttons
                          }}
                        >
                          <PinInput
                            length={6}
                            focus
                            type="password"
                            onChange={(value, index) => {
                              setMpin(value);
                            }}
                            regexCriteria={/^[0-9]*$/}
                            inputStyle={{
                              background: "white",
                              borderColor: "white",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            mt: 1,
                            gap: 2,
                          }}
                        >
                          <ResetMpin variant="standard" bgColor="white" />
                          <button
                            type="submit"
                            style={{
                              textTransform: "none",
                              backgroundColor: "#fff",
                              color: "#000",
                              padding: "4px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              cursor: "pointer",
                              fontSize: "13px",
                            }}
                            onClick={handleSubmit}
                          >
                            <Typography style={{ fontSize: "12px" }}>
                              Proceed
                            </Typography>
                          </button>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {err && err && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                        gap: 4,
                        fontSize: "12px",
                        px: 1,
                        color: "#fff",
                      }}
                    >
                      {err.message && err.message && (
                        <div>{err && err.message}</div>
                      )}

                      {err.data && err.message === "Invalid M Pin" && (
                        <div className="blink_text">
                          Attempts remaining:{err && 5 - Number(err.data)}
                        </div>
                      )}
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "24px", sm: "16px" },
                        fontWeight: "bold",
                        color: "white", // Ensure the text is visible over the image
                      }}
                    >
                      Verify Account
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Proceed your journey with IMPS GURU
                    </Typography>
                  </Box>

                  {/* <ModalFooter
              form="expMoneyTransfer"
              request={request}
              btn="Proceed"
            /> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default AccountVerificationModal;
