import * as React from "react";
import { useState } from "react";
import { Box, Modal, Typography, Avatar, Grid, Tooltip } from "@mui/material";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import { info } from "../iconsImports";
import ApiEndpoints from "../network/ApiEndPoints";
import { patchJsonData } from "../network/ApiController";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import Loader from "../component/loading-screen/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  height: "max-content",
  overflowY: "scroll",
  p: 2,
};

const ActiveInactiveOpServices = ({ row, refresh }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);

  const blockUnblock = (event) => {
    event.preventDefault();
    patchJsonData(
      ApiEndpoints.ADMIN_OP_SERVICE,
      { id: row?.id },
      "",
      setRequest,
      (resp) => {
        okSuccessToast("Services Updated..");
        if (refresh) refresh();
        setOpen(false); // Close modal after update
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ display: "grid", justifyContent: "center" }}>
      {/* Lock Icon */}
      <Box sx={{ width: "100%", mr: 3 }}
      //  onClick={handleOpen}
       >
        {row.status === 1 ? (
          <Tooltip title="Active">
            <LockOpenOutlined sx={{ color: "#00BF78", cursor: "pointer" }} />
          </Tooltip>
        ) : (
          <Tooltip title="In-Active">
            <LockOutlined sx={{ color: "#DC5F5F", cursor: "pointer" }} />
          </Tooltip>
        )}
      </Box>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <Loader loading={request} />
          <ModalHeader title="Block Un-block Service" handleClose={handleClose} />
          <Box
            component="form"
            id="blockUnblockId"
            noValidate
            autoComplete="off"
            onSubmit={blockUnblock}
            sx={{
              "& .MuiTextField-root": { m: 2 },
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar src={info} sx={{ width: 160, height: 160, objectFit: "cover" }} alt="logo" />
            </Box>
            <Typography variant="h6">
              {row.active !== 1 ? "Activate Service" : "In-Activate Service"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {row.active !== 1
                ? `Do you want to Activate Service ${row.name}?`
                : `Do you want to In-Activate Service ${row.name}?`}
            </Typography>
          </Box>
          <ModalFooter form="blockUnblockId" request={request} btn="Proceed" />
        </Box>
      </Modal>
    </Box>
  );
};

export default ActiveInactiveOpServices;
