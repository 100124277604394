import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useRef } from "react";
import { cmsIcon, back } from "../iconsImports";
import { useState } from "react";
import AuthContext from "../store/AuthContext";
import { useContext } from "react";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import Mount from "../component/Mount";
import { Icon } from "@iconify/react";
import Loader from "../component/loading-screen/Loader";
import { useEffect } from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";
import BillDetailsModal from "../modals/BillDetailsModal";
import CommonMpinModal from "../modals/CommonMpinModal";
import { validateApiCall } from "../utils/LastApiCallChecker";
import useCommonContext from "../store/CommonContext";
import CryptoJS from "crypto-js";
import useResponsive from "../hooks/useResponsive";
import CustomTabs from "../component/CustomTabs";
import { cms_tab_value, mt_tab_value } from "../utils/constants";
import { styled } from "@mui/material/styles";
import CommonCardServices from "./CommonCardServices";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const OuterIcon = styled(Box)(({ theme, bg = "#08509E" }) => ({
  top: "-12px",
  zIndex: 1,
  right: "-12px",
  width: "100px",
  height: "100px",
  display: "flex",
  borderRadius: "50%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  background: bg,
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px inset",
}));

const InnerIcon = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "48px",
  height: "48px",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  background: theme.palette.common.white,
}));

const CMSView = ({ resetView, id, type, dataCategories, handleCardClick }) => {
  const [request, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cmsType, setCmsType] = useState("cms1");
  const authCtx = useContext(AuthContext);
  const location = authCtx.location;
  const [url, setUrl] = useState(undefined);
  const [hardCodeLoading, setHardCodeLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  // const [bbpsLoanEmiType, setBbpsLoanEmiType] = useState([]);
  const [currentBiller, setCurrentBiller] = useState(false);
  // console.log("currentBiller", currentBiller);
  const [billerId, setBillerId] = useState("");
  const [billDetails, setBillDetails] = useState(false);
  const [params, setParams] = useState([]);
  const [fetchMandatory, setFetchMandatory] = useState("");
  const [categoryName, setCategoryName] = useState("Loan EMI");
  const [payRequest, setPayRequest] = useState(false);
  const [billValue, setBillValue] = useState();
  const [err, setErr] = useState();
  const [openMpin, setOpenMpin] = useState(false);
  const [mpinVal, setMpinVal] = useState(false);
  const { getRecentData } = useCommonContext();
  const isMobile = useResponsive("md", "down");
  const [tabValue, setTabValue] = useState("tab1");
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState("cms1"); // Default active tab value
  const scrollContainerRef = useRef(null);
  const handleBack = () => {
    resetView(false);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.billerName,
  });

  const getBillers = () => {
    setOperators([]);
    setParams([]);
    setCurrentBiller("");
    postJsonData(
      ApiEndpoints.BBPS_GET_BILLERS,
      { categoryKey: "C13" },
      setLoading,
      (res) => {
        const data = res?.data?.data?.records;
        setOperators(data);
        setFetchMandatory("");
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  // GET BBPS Billers
  const getBillersDetails = (billerId) => {
    postJsonData(
      ApiEndpoints.BBPS_GET_BILLERS_DETAILS,
      { billerId: billerId },
      setLoading,
      (res) => {
        const data = res.data.data;
        setParams(data.parameters);
        setFetchMandatory(data.fetchRequirement);
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  // fetch bill
  const fetchBill = (event) => {
    const data = {
      billerId: billerId,
      latitude: location.lat,
      longitude: location.long,
      amount: 0,
    };
    params.map((item) => {
      let propertyName = item.name;
      data[propertyName] =
        item.inputType === "NUMERIC"
          ? Number(document.getElementById(propertyName).value)
          : document.getElementById(propertyName).value;
      return data;
    });

    if (data.param1 === "") {
      apiErrorToast("Please fill out all the fields");
    } else if (data.hasOwnProperty("param2") && data.param2 === "") {
      apiErrorToast("Please fill out all the fields");
    } else if (data.hasOwnProperty("param3") && data.param3 === "") {
      apiErrorToast("Please fill out all the fields");
    } else if (data.hasOwnProperty("param4") && data.param4 === "") {
      apiErrorToast("Please fill out all the fields");
    } else {
      postJsonData(
        ApiEndpoints.BBPS_FETCH_BILL,
        data,
        setLoading,
        (res) => {
          setBillDetails(res.data.data.data);
        },
        (err) => {
          apiErrorToast(err);
        }
      );
    }
  };

  // pay bill
  const payBill = (event) => {
    event.preventDefault();
    const data = {
      billerId: billerId && billerId,
      biller_name: currentBiller?.billerName,
      amount: billValue,
      pf: "web",
      cat: "C13",
      mpin: mpinVal,
      latitude: location.lat,
      longitude: location.long,
      enquiryReferenceId: billDetails
        ? billDetails.enquiryReferenceId
        : "15486sfdgyf",
    };
    params &&
      params.map((item) => {
        let propertyName = item.name;
        data[propertyName] =
          item.inputType === "NUMERIC"
            ? Number(document.getElementById(propertyName).value)
            : document.getElementById(propertyName).value;
        return data;
      });
    if (validateApiCall()) {
      postJsonData(
        ApiEndpoints.BBPS_PAY_BILL,
        data,
        setPayRequest,
        (res) => {
          okSuccessToast(res.data.message);
          getRecentData();
          setBillDetails(false);
          setMpinVal(false);
          setErr("");
        },
        (error) => {
          setMpinVal(false);
          apiErrorToast(error);
          getRecentData();
          setErr("");
          // setBillDetails(false);
        }
      );
    } else {
      const error = {
        message: "Kindly wait some time before another request",
      };
      setErr(error);
    }
  };

  useEffect(() => {
    // getBillersDetails();
    getBillers();
  }, []);

  // CMS 1 handle submit
  const handleSubmit = (event) => {
    setHardCodeLoading(true);
    event.preventDefault();
    let amount = document.getElementById("amount").value;
    let mobile = document.getElementById("mob").value;
    const data = {
      amount,
      latitude: location?.lat,
      longitude: location.long,
      pf: "web",
      mobile,
      additionalParams: null,
    };
    postJsonData(
      ApiEndpoints.CREATE_ORDER_CMS,
      data,
      setRequest,
      (res) => {
        const wholeRes = res.data.data;
        let parsedBase64Key = CryptoJS.enc.Base64.parse(
          wholeRes.superMerchantSkey.substring(0, 32)
        );

        // console.log("parsedBase64Key", parsedBase64Key);

        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(wholeRes),
          parsedBase64Key,
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          }
        );

        // skey : substring (0,32)
        //

        // console.log("encrypted", encrypted);

        const encryptedString = btoa(encrypted);

        // console.log("encryptedString", encryptedString);

        if (encryptedString) {
          setUrl(
            `https://fpuat.tapits.in/UberCMSBC/#/login?data=${encryptedString}&skey=${wholeRes.superMerchantSkey.substring(
              0,
              32
            )}`
          );

          // window.open(
          //   `https://fpuat.tapits.in/UberCMSBC/#/login?data=${encryptedString}&skey=${wholeRes.superMerchantSkey}`,
          //   "_blank"
          // );

          //   setTimeout(() => {
          //     setHardCodeLoading(false);
          //   }, 1500);
        }
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  if (request) {
    return (
      <Grid sx={{ position: "relative" }}>
        <div>
          <Loader loading={request} />
        </div>
      </Grid>
    );
  }

  const tabs = [{ label: "CMS 1" }, { label: "CMS 2" }];
  const handleChange = (event, newValue) => {
    console.log("newval", newValue);
    setValue(newValue);
    setCmsType(cms_tab_value[newValue]);
    // setCurrentType(cms_tab_value[newValue])
    console.log("cms value is", cmsType);
  };
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ mb: { md: 2, sm: 4, xs: 4 },  }}
        >
          {dataCategories
            ?.filter((category) => category.title === "Banking")
            .map((category, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  margin: "0 auto",
                  marginBottom: 1,
                  border: "1px solid lightgray",
                  padding: { lg: 1.1, md: 1.2, xs: 2, sm: 3 },
                  borderRadius: 3,

                  paddingRight: 1,
                  flexWrap: "wrap",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{
                    ml: 0.5,
                    mt: "-8px",
                    fontSize: { xs: "1rem", sm: "1.25rem" },
                  }}
                >
                  {category.title}
                </Typography>

                <Box
                  sx={{
                    // overflowX: "scroll",
                    height: "100px",
                    display: "flex",
                    mt: -2,
                    flexDirection: "row",
                    alignItems: "center",
                    width: {
                      lg: "67vw",
                      md: "67vw",
                      sm: "82vw",
                      xs: "82vw",
                    },
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleScroll("left")}
                    sx={{
                      position: "absolute",
                      left: 0,
                      mt: 1,
                      zIndex: 1,
                      color: "#000",
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Box
                    ref={scrollContainerRef}
                    sx={{
                      display: "flex",
                      width: "100%",
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      padding: 1,
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide the scrollbar
                      },
                   
                    }}
                  >
                    {category.data.map((item) => (
                      <Box
                        sx={{
                          px: 1,
                        }}
                      >
                        <CommonCardServices
                          title={item.name}
                          img={item.img}
                          isSelected={id === item.id}
                          onClick={() => handleCardClick(item)}
                          width="200px"
                        />
                      </Box>
                    ))}
                  </Box>
                  <IconButton
                    onClick={() => handleScroll("right")}
                    sx={{
                      position: "absolute",
                      right: "-17px",
                      mt: 1,
                      zIndex: 1,
                      // ml: 2,
                      color: "#000",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          <CustomTabs tabs={tabs} value={value} onChange={handleChange} />

          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // height: "100vh",
              position: "relative",
            }}
          >
            {/* <OuterIcon>
              <InnerIcon>
                <img src={cmsIcon} alt="cms" width="140%" />
              </InnerIcon> */}
            {/* </OuterIcon> */}
         

<Mount visible={url}>
  <Grid container >
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "hidden",
        bgcolor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        p: 2,
      }}
    >
      {/* Close Button */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={() => setUrl(undefined)} sx={{ fontSize: 32 }}>
          <Icon icon="iconamoon:close-fill" />
        </IconButton>
      </Box>

   
      <Box
        component="iframe"
        src={url}
        title="description"
        frameBorder="0"
        marginHeight="0"
        sx={{
          width: { xs: "100%", sm: "90%", md: "100%" },
          height: "600px",
          // borderRadius: 2,
          p:2,
          // boxShadow: 2,
        }}
      />
    </Grid>
  </Grid>
</Mount>;

            <Mount visible={!url}>
              <Grid
                container
                sx={{
                  width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                  px: { md: 5, sm: 3, xs: 1 },
                  py: 3,
                  position: "relative",
                }}
                className="card-css"
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Distribute items evenly
                  }}
                >
                  {/* Left Section: Arrow Button */}
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      onClick={handleBack}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        p: 0,
                        ml:5,
                        mr: 1,
                      }}
                    >
                      <ArrowBackIcon sx={{ color: "#000", fontSize: "24px" }} />
                    </Button>
                  </Grid>

                  {/* Center Section: Typography */}
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: "flex",
                      justifyContent: "center", // Center the text horizontally
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "20px",
                      }}
                    >
                      {cmsType === "cms1"
                        ? "Cash Management System 1"
                        : "Cash Management System 2"}
                    </Typography>
                  </Grid>

                  {/* Right Section: Placeholder (to balance layout) */}
                </Grid>

              
                {cmsType === "cms1" ? (
                  <Grid item md={12} xs={12}>
                    <Box
                      component="form"
                      id="cms_transfer"
                      validate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                      sx={{
                        "& .MuiTextField-root": { m: 2 },
                      }}
                    >
                      <Grid container sx={{ pt: 1 }}>
                        <Grid item md={12} xs={12}>
                          <FormControl sx={{ width: "90%" }}>
                            <TextField
                              autoComplete="off"
                              label="Amount"
                              id="amount"
                              size="small"
                              type="number"
                              inputProps={{
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                              InputProps={{
                                inputProps: {
                                  max: 500000,
                                  min: 10,
                                },
                              }}
                              required
                              onKeyDown={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormControl sx={{ width: "90%" }}>
                            <TextField
                              autoComplete="off"
                              label="Mobile"
                              id="mob"
                              size="small"
                              type="number"
                              inputProps={{
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                              required
                            />
                          </FormControl>
                        </Grid>
                      
                      </Grid>
                    </Box>
                    <Button
                      type="submit"
                      form="cms_transfer"
                      className="btn-background"
                      sx={{
                        width: "87%",
                        mt: 1,
                      }}
                      disabled={request}
                    >
                      <span>Proceed</span>
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                      <Loader loading={loading} />
                      <Autocomplete
                        filterOptions={filterOptions}
                        autoHighlight
                        openOnFocus
                        selectOnFocus
                        id="biller"
                        // freeSolo
                        options={operators}
                        value={currentBiller.billerName}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setCurrentBiller(newValue);
                            setBillerId(newValue.billerId);
                            getBillersDetails(newValue.billerId);
                          } else {
                            setCurrentBiller("");
                            setBillerId("");
                          }
                        }}
                        getOptionLabel={(option) => {
                          return currentBiller.billerName
                            ? currentBiller.billerName
                            : "Select Biller";
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": { mr: 2, flexShrink: 0 },
                              fontSize: "12px",
                            }}
                            {...props}
                          >
                            <Typography>{option.billerName}</Typography>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <FormControl sx={{ width: "87%" }}>
                            <TextField
                              autoComplete="off"
                              {...params}
                              // autoFocus
                              id="biller_textfield"
                              label="Select Biller"
                              size="small"
                              sx={{
                                textAlign: "center",
                              }}
                             
                            />
                          </FormControl>
                        )}
                        clearIcon={
                          <BackspaceIcon
                            sx={{ fontSize: "15px", ml: 0 }}
                            onClick={() => {
                              setCurrentBiller("");
                              setBillerId("");
                            }}
                          />
                        }
                      />
                    </Grid>

                    {/* ##### map the params that comes from selecting the operators #####*/}
                    {params &&
                      params.map((item, index) => {
                        return (
                          <Grid item md={12} xs={12} key={index} sx={{ mt: 2 }}>
                            <FormControl
                              sx={{
                                width: "87%",
                              }}
                            >
                              <TextField
                                autoComplete="off"
                                label={item.desc}
                                id={item.name}
                                inputProps={{
                                  minLength: item.minLength,
                                  maxLength: item.maxLength,
                                  pattern: item.regex,
                                }}
                                // inputProps={{ style: { textTransform: "uppercase" } }}
                                size="small"
                                minLength={item.minLength}
                                maxLength={item.maxLength}
                                required={item.mandatory === 1}
                                type={
                                  item.inputType && item.inputType === "NUMERIC"
                                    ? "number"
                                    : "text"
                                }
                              />
                            </FormControl>
                          </Grid>
                        );
                      })}
                    {err && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 2,
                          fontSize: "12px",
                          px: 2,
                          color: "#DC5F5F",
                        }}
                      >
                        {err.message && err.message && (
                          <div>{err && err.message}</div>
                        )}
                      </Box>
                    )}
                    <Grid item md={12} xs={12}>
                      <FormControl sx={{ mt: 2, width: "87%" }}>
                        {fetchMandatory && fetchMandatory === "MANDATORY" && (
                          <BillDetailsModal
                            billerId={billerId}
                            params={params}
                            currentBiller={currentBiller}
                            billDetails={billDetails}
                            setBillDetails={setBillDetails}
                            fetchBill={fetchBill}
                            categoryName={categoryName}
                            payRequest={payRequest}
                            payBill={payBill}
                            mpinVal={mpinVal}
                            setMpinVal={setMpinVal}
                            setOpenMpin={setOpenMpin}
                            billValue={billValue}
                            setBillValue={setBillValue}
                            err={err}
                          />
                        )}
                        {/* chrome auto error not showing in bbps here  */}
                        {fetchMandatory &&
                          fetchMandatory === "NOT_SUPPORTED" && (
                            <Button
                              type="submit"
                              form="bbpsForm"
                              className="btn-background"
                              sx={{
                                width: "100%",
                                mt: 1,
                              }}
                            >
                              {mpinVal ? "Pay Now" : "Continue"}
                            </Button>
                          )}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Mount>
            <CommonMpinModal
              open={openMpin}
              setOpen={setOpenMpin}
              mPinCallBack={(mPinValue) => {
                setMpinVal(mPinValue);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CMSView;
