import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";

// import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { useState } from "react";
import PinInput from "react-pin-input";
import AuthContext from "../store/AuthContext";
import { useContext } from "react";
import { breakAmt, performMt } from "../utils/MTBreakAmtUtil";
import fail_anim from "../assets/animate-icons/fail.json";
import success_anim from "../assets/animate-icons/success_anim.json";
import { backgroundMT, Logo } from "../iconsImports";
import { AnimateIcon28 } from "../component/AnimateIcon28";
import { postJsonData } from "../network/ApiController";

import useCommonContext from "../store/CommonContext";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Spinner from "../commons/Spinner";

// import { useEffect } from "react";
import { validateApiCall } from "../utils/LastApiCallChecker";
import { secondaryColor } from "../theme/setThemeColor";
import ApiEndpoints from "../network/ApiEndPoints";
import Mount from "../component/Mount";
import ModalHeader from "../modals/ModalHeader";
// import MyButton from "./MyButton";
import MyButton from "../component/MyButton";
import ModalFooter from "../modals/ModalFooter";
import ConfirmationModal from "../modals/ConfirmationModal";
import ResetMpin from "../modals/ResetMpin";
// import Loader from "../commons/Spinner";
import Loader from "../component/loading-screen/Loader";
import CloseIcon from "@mui/icons-material/Close";

const CommonMoneyTransferModal = ({
  type,
  ben,
  rem_number,
  view,
  limit_per_txn,
  remDailyLimit,
  rem_details,
  apiEnd,
  dmtValue,
}) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const [mpin, setMpin] = useState("");
  const [arrAmtRes, setArrAmtRes] = useState([]);
  // console.log("arrAmtRes", arrAmtRes);
  const [err, setErr] = useState();
  const [mtRequest, setMtRequest] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  // const [expressComplete]
  const [amount, setAmount] = useState("");
  const { getRecentData,refreshUser } = useCommonContext();
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const loc = authCtx.location && authCtx.location;
  // const [ifConfirmed, setIfConfirmed] = useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [isOtpShow, setIsOtpShow] = useState(false);
  const [remOtp, setRemOtp] = useState();
  const [stateresp, setStateresp] = useState("");
  const [referenceKey, setReferenceKey] = useState("");
  const [remainingTime, setRemainingTime] = useState(null); // State for remaining time
  const [timerInterval, setTimerInterval] = useState(null); // State for the interval ID
  const [validity, setValidity] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: "max-content",
  };

  const getDynamicData = (
    type,
    view,
    rem_details,
    rem_number,
    remOtp,
    stateresp,
    amt,
    limit_per_txn,
    referenceKey
  ) => {
    console.log("Entered getDynamicData with view:", view);
    let data;
    if (view === "Money Transfer") {
      data = {
        number: rem_number && rem_number,
        amount: amount,
        ben_acc: ben.bene_acc ? ben.bene_acc : ben.accno,
        bank_name: view === "Express Transfer" ? ben.bank : undefined,
        ben_id: ben.benid||ben.bene_id,
        ifsc: ben.ifsc,
        latitude: loc.lat,
        longitude: loc.long,
        ben_name: ben.bene_name || ben.name,
        referenceKey: referenceKey ? referenceKey : undefined,
        stateresp: stateresp,
        kyc: limit_per_txn && limit_per_txn * 1 > 5000 ? 1 : 0,
        rem_type: "NONKYC",
        type: type,
        pf: "WEB",
        mpin: mpin,
        otp: remOtp,
        pipe:
          rem_details.bank1_limit !== 0
            ? "bank1"
            : rem_details.bank2_limit !== 0
            ? "bank2"
            : rem_details.bank3_limit !== 0
            ? "bank3"
            : undefined,
      };
    } else if (view === "Express Transfer" || view === "Super Transfer") {
      data = {
        number: rem_number && rem_number,
        amount: amount,
        ben_acc: ben.bene_acc ? ben.bene_acc : ben.accno,
        bank_name: view === "Express Transfer" ? ben.bank : undefined,
        ben_id: ben.benid ? ben.benid : ben.id,
        ifsc: ben.ifsc,
        latitude: loc.lat,
        longitude: loc.long,
        ben_name: ben.bene_name ? ben.bene_name : ben.name,
        type: type,
        pf: "WEB",
        otp: remOtp,
        otp_ref: stateresp,
        mpin: mpin,
        pipe:
          rem_details.bank1_limit !== 0
            ? "bank1"
            : rem_details.bank2_limit !== 0
            ? "bank2"
            : rem_details.bank3_limit !== 0
            ? "bank3"
            : undefined,
      };
    } else {
      data = {
        vpa: ben.bene_acc,
        latitude: loc.lat,
        longitude: loc.long,
        number: rem_number,
        pf: "WEB",
        mpin: mpin,
        amount: amt,
        name: ben.bene_name,
        otp_ref: stateresp,
        otp: remOtp,

      };
    }
    return data;
  };

  const getDynamicEndpoint = (type, view) => {};
  // console.log("view is ",view)
  const handleOpen = () => {
    if (rem_details) {
      if (!validity) setValidity(rem_details?.validity);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setArrAmtRes([]);
    setOnComplete(false);
    setMpin("");
    setErr("");
    setAmount("");
    setIsOtpShow(false);
    setStateresp("");
    setRemOtp("");
    setValidity("");
  };

  const handleSubmitMoneyTransfer = (event) => {
    console.log("im indside apicall 2");

    event.preventDefault();
    if (openConfirm) setOpenConfirm(false);
    if (amount === "") {
      setErr("");
      const error = {
        message: "Amount required",
      };
      setErr(error);
    } else if (mpin === "") {
      // console.log("helloo imhere");
      setErr("");
      const error = {
        message: "MPIN required",
      };
      setErr(error);
    } else {
      let amt = parseInt(amount && amount);
      if (amt < 2 || amt > 25000) {
        setErr("");
        const error = {
          message: "Amount must be between 2 to 25000",
        };
        setErr(error);
      } else if (amt && amt > parseInt(user.w1)) {
        setErr("");
        const error = {
          message: "Low Balance",
        };
        setErr(error);
      } else {
        setErr("");

        const amt_arr = breakAmt(amt, limit_per_txn);
        const arrData = amt_arr.map((item, index) => {
          return {
            amount: item,
            meta: {
              processing: false,
            },
          };
        });
        setArrAmtRes(arrData);
        if (arrData && arrData.length > 0) {
          const Dynamicdata = getDynamicData(
            type,
            view,
            rem_details,
            rem_number,
            remOtp,
            stateresp,
            amount,
            limit_per_txn,
            referenceKey
          );
          console.log("the dynamci data is ", Dynamicdata);
          // const postData = {
          //   number: rem_number && rem_number,
          //   ben_acc: ben.account ? ben.account : ben.bene_acc,
          //   ben_id: ben.benid ? ben.benid : ben.id,
          //   ifsc: ben.ifsc,
          //   latitude: loc.lat,
          //   longitude: loc.long,
          //   ben_name: ben.name ? ben.name : ben.bene_name,
          //   type: type,
          //   pf: "WEB",
          //   mpin: mpin,
          //   otp: remOtp,
          //   stateresp: stateresp,
          //   referenceKey: referenceKey ? referenceKey : undefined,
          //   rem_type: "NONKYC",
          //   kyc: limit_per_txn && limit_per_txn * 1 > 5000 ? 1 : 0,
          //   pipe:
          //     rem_details.bank1_limit !== 0
          //       ? "bank1"
          //       : rem_details.bank2_limit !== 0
          //       ? "bank2"
          //       : rem_details.bank3_limit !== 0
          //       ? "bank3"
          //       : undefined,
          // };
          const len = arrData.length;

          // console.log("data", postData);

          if (validateApiCall()) {
            performMt(
              0,
              len,
              arrData,
              // postData,
              Dynamicdata,

              // on partial success
              (index, res) => {
                // console.log(`partSuccess => ${index} => ${JSON.stringify(res)}`);
                setArrAmtRes([...arrData]);
                // console.log("index : " + res);
              },
              // onSuccess
              (data) => {
                let beneData = { ...ben };
                beneData.choosenTransferType = type;
                beneData.transfer_type = "MT";
                beneData.remNumb = rem_details?.mobile;
                beneData.remName = `${
                  rem_details?.firstName
                    ? rem_details?.firstName
                    : rem_details?.fname
                } ${
                  rem_details.lastName
                    ? rem_details.lastName
                    : rem_details.lname
                }`;
                localStorage.setItem("MoneyTransfer", JSON.stringify(beneData));
                localStorage.setItem("items", JSON.stringify(data));
                setArrAmtRes([...arrData]);
                // console.log("bene data mt", beneData);
              },

              // onComplete
              () => {
                // okSuccessToast(
                //   "Success",
                //   "All Transactions completed successfully"
                // );
                getRecentData();
                setOnComplete(true);
              },
              (index, error) => {
                // onFailed
                setMtRequest(true);
                setOnComplete(false);
                setOpenConfirm(false);
                arrData.map((item) => {
                  if (!item.meta.res) {
                    item.meta.processing = false;
                    item.meta.error = "Transaction Aborted";
                  }
                });
                apiErrorToast(error);
                arrData[index].meta.error = JSON.stringify(
                  error.response.data.message
                );
                if (error.response.data.data && error.response.data.data) {
                  arrData[index].meta.data = error.response.data.data;
                }
                getRecentData();
                setMtRequest(false);
                setArrAmtRes([...arrData]);
              },
              setMtRequest,
              apiEnd,
              setOpenConfirm
            );
          } else {
            setErr("");
            const error = {
              message: "Kindly wait some time before another request",
            };
            setErr(error);
          }
        }
      }
    }
  };

  const handleOpenVerify = () => {
    console.log("im indside apicall 1");

    let amt = parseInt(amount && amount);
    if (view === "Money Transfer" && (amt < 2 || amt > 25000)) {
      setErr("");
      const error = {
        message: "Amount must be between 2 to 25000",
      };
      setErr(error);
    } else if (amount === "") {
      setErr("");
      const error = {
        message: "Amount required",
      };
      setErr(error);
    } else if (mpin === "") {
      setErr("");
      const error = {
        message: "MPIN required",
      };
      setErr(error);
    } else {
      setOpenConfirm(true);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    let amt = parseInt(amount);
    // Validation Logic
    if (amount === "") {
      setErr({ message: "Amount required" });
      return;
    }
    if (mpin === "") {
      setErr({ message: "MPIN required" });
      return;
    } else if (amt && amt > parseInt(user.w1)) {
      setErr("");
      const error = {
        message: "Low Balance",
      };
      setErr(error);
    } else {
      getDynamicData(
        type,
        view,
        rem_details,
        rem_number,
        remOtp,
        stateresp,
        amount,
        limit_per_txn,
        referenceKey
      );
    }
    const Dynamicdata = getDynamicData(
      type,
      view,
      rem_details,
      rem_number,
      remOtp,
      stateresp,
      amount,
      limit_per_txn,
      referenceKey
    );
    console.log("the dynamci data is in express vendor  ", Dynamicdata);

    setRequest(true);

    postJsonData(
      view === "upi" ? ApiEndpoints.UPI_PAY : apiEnd,
      Dynamicdata,
      setRequest,
      (res) => {
        const rrn = res.data.RRN;
        // console.log("rrn", rrn);
        getRecentData();

        okSuccessToast(res.data.message);
        setOnComplete(true);
        setOpenConfirm(false);
        setErr("");
        if (rrn) {
          let receiptData = { ...ben };

          receiptData.choosenTransferType = type;
          receiptData.rrn = rrn;
          receiptData.amount = Dynamicdata.amount;
          receiptData.transfer_type = "ET";
          receiptData.status = "SUCCESS";
          receiptData.remNumb = rem_details?.mobile;
          receiptData.remName = `${
            rem_details?.firstName ? rem_details?.firstName : rem_details?.fName
          } ${rem_details.lastName ? rem_details.lastName : rem_details.lName}`;
          localStorage.setItem("MoneyTransfer", JSON.stringify(receiptData));
          // console.log("receiptData", receiptData);
        }
        refreshUser()
        setIsOtpShow(true);
        // handleClose();
      },
      (error) => {
        getRecentData();
        refreshUser()
        if (error && error.response) {
          if (error.response.data.message === "Invalid M Pin") {
            setErr(error.response.data);
            apiErrorToast(error);
          } else {
            apiErrorToast(error);
          }
        } else {
          setErr("");
          const error = {
            message: "Kindly wait some time before another request",
          };
          setErr(error);
        }
        // refreshUser()
      }
    );
  };
  const handleOtpChange = (event) => {
    const value = event.target.value;

    // Prevent input if it's not numeric
    if (/[^0-9]/.test(value)) {
      return;
    }

    if (value.length <= 6) {
      setErr(""); // Clear error if length is valid
      setRemOtp(value); // Update OTP value
    }
  };
  console.log("benId",ben);
  
  const sendOtpMt = () => {
    console.log("im indside apicall", dmtValue);
    if (view === "Money Transfer") {
      const data = {
        number: rem_number && rem_number,
        amount: amount && amount,
        ben_id: ben.benid||ben.bene_id,
        latitude: loc.lat,
        longitude: loc.long,
        type: type,
        pf: "WEB",
        referenceKey: dmtValue == "dmt1" ? rem_details.referenceKey : undefined,
      };
      console.log("rem_details", rem_details.referenceKey);
      const apiEnd =
        dmtValue == "dmt1"
          ? ApiEndpoints.OTP_dmt1
          : dmtValue == "dmt3"
          ? ApiEndpoints.SEND_OTP_DMT3
          : ApiEndpoints.OTP_PSPRINT;
      postJsonData(
        apiEnd,
        data,
        setRequest,
        (res) => {
          console.log("reeeees", res?.data?.data?.referenceKey);
          if (res?.data && dmtValue === "dmt2") {
            setStateresp(res?.data?.data);
          }
          if (res?.data?.data?.referenceKey) {
            setReferenceKey(res?.data?.data?.referenceKey);
          }
          if (res?.data?.data?.validity) {
            setValidity(res?.data?.data?.validity);
          }

          setIsOtpShow(true);
        },
        (err) => {
          apiErrorToast(err);
          console.log("errrrrr", err);
        }
      );
    } else if (view === "Express Transfer" || view === "Super Transfer") {
      const data = {
        number: rem_number && rem_number,
        amount: amount && amount,
        ben_id: ben.benid,
        latitude: loc.lat,
        longitude: loc.long,
        type: type,
        pf: "WEB",
      };
      postJsonData(
        ApiEndpoints.OTP_EXP,
        data,
        setRequest,
        (res) => {
          console.log("reeeees", res);
          setStateresp(res?.data?.data);
          setIsOtpShow(true);
        },
        (err) => {
          console.log("errrrrr", err);
        }
      );
    } else {
      const data = {
        number: rem_number && rem_number,
        amount: amount && amount,
        latitude: loc.lat,
        longitude: loc.long,
        type: type,
        pf: "WEB",
      };
      postJsonData(
        ApiEndpoints.OTP_EXP,
        data,
        setRequest,
        (res) => {
          console.log("reeeees", res);
          setStateresp(res?.data?.data);
          setIsOtpShow(true);
        },
        (err) => {
          console.log("errrrrr", err);
        }
      );
        }
  };

  React.useEffect(() => {
    if (validity && dmtValue == "dmt1") {
      const validityDate = new Date(validity);

      if (isNaN(validityDate.getTime())) {
        console.error("Invalid validity:", validity);
        setRemainingTime("Invalid time");
        return;
      }

      const updateRemainingTime = () => {
        const currentTime = new Date();
        const diffTime = validityDate - currentTime;

        if (diffTime <= 0) {
          clearInterval(timerInterval);
          setRemainingTime("Time expired");
        } else {
          setRemainingTime(diffTime);
        }
      };

      const intervalId = setInterval(updateRemainingTime, 1000);
      setTimerInterval(intervalId);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [validity]); //

  const formatRemainingTime = (time) => {
    if (time === "Invalid time" || time === "Time expired") {
      return time;
    }

    const totalSeconds = Math.floor(time / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      {view == "upi" ? (
        <Button
          size="small"
          id="verify-btn"
          className="button-red-outline"
          sx={{ fontSize: "13px", py: 0, ml: 1, px: 1 }}
          onClick={handleOpen}
        >
          <span style={{ marginRight: "5px" }}>UPI</span>
          {/* <img src={UpiLogo} alt="UPI logo" style={{ width: '18px', height: '20px' }} /> */}
        </Button>
      ) : (
        <Button
          className={
            type === "NEFT" ? "button-purple-outline" : "button-red-outline"
          }
          sx={{ fontSize: "13px", py: 0, ml: 1, px: 1 }}
          onClick={handleOpen}
        >
          <CallMadeIcon sx={{ fontSize: "14px", mr: 0.5 }} />
          {type && type}
        </Button>
      )}
      <Modal
        open={open}
        // onClose={!mtRequest && handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8, // Adjust this for horizontal spacing
              color: "grey", // Color of the close icon (you can change it)
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="form"
            id="expMoneyTransfer"
            validate
            autoComplete="off"
            onSubmit={
              view && view !== "Money Transfer"
                ? handleSubmit
                : handleSubmitMoneyTransfer
            }
            sx={{
              "& .MuiTextField-root": { m: 1 },
              // position: "relative",
              // mt: { lg: "15%", sm: "20%", xs: "20%" },
              width: "100%",
            }}
          >
            <Loader loading={request} />

            <Grid
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                padding: 1.5,
                zIndex: 1,
                mt: 2,
                width: "70.2%",
                ml: "14%",

                bgcolor: "#f5f5f5",
                boxShadow: 2,
                borderRadius: 2,
                mb: 2,
              }}
            >
              {view === "upi" ? (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>Name</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.bene_name||ben.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>VPA</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.bene_acc}
                    </td>
                  </tr>
                </table>
              ) : (
                <table style={{ width: "100%", borderSpacing: "0 10px" }}>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>Name</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.bene_name||ben.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>Bank Name</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.bank || ben.bankname}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>Account</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.accno || ben.bene_acc}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 5px" }}>IFSC</td>
                    <td style={{ padding: "5px 5px" }}>:</td>
                    <td style={{ textAlign: "right", padding: "5px 5px" }}>
                      {ben.ifsc}
                    </td>
                  </tr>
                </table>
              )}
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                mt: 5,
                width: "100%",
                margin: "0 auto",
              }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: view === "upi" ? "280px" : "310px",
                    bgcolor: "#ffffff",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: 3,
                    display: "flex",
                    flexDirection: "column",

                    backgroundImage: `url(${backgroundMT})`,
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 2,
                      }}
                      hidden={onComplete}
                    >
                      {/* Amount and OTP Input Row */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",

                          width: "72.6%", // Responsive width
                          gap: 0.7,
                          mr: 1,
                        }}
                      >
                        {/* Amount Input */}
                     {view!=="upi"?(
                     <FormControl fullWidth>
                     <TextField
                       placeholder="Enter Amount"
                       id="amount"
                       size="small"
                       type="number"
                       required
                       value={amount}
                       autoComplete="off"
                       sx={{ background: "white", borderRadius: 1 }}
                       InputProps={{
                         inputProps: { min: "0", max: remDailyLimit },
                         endAdornment: 
                           <InputAdornment position="end">
                             {((dmtValue === "dmt1" && amount > 10) ||
                               (dmtValue !== "dmt1"  &&  amount >= 100 )) &&
                               !isOtpShow && (
                                 <Button
                                   sx={{
                                     py: 0.5,
                                     fontSize: "10px",
                                     px: 0.8,
                                   }}
                                   variant="contained"
                                   onClick={sendOtpMt}
                                   disabled={!!err?.data}
                                 >
                                   Get OTP
                                 </Button>
                               )}
                           </InputAdornment>
                         
                       }}
                       onChange={(e) => setAmount(e.target.value)}
                       onKeyDown={(e) => {
                         if (e.key === "+" || e.key === "-")
                           e.preventDefault();
                       }}
                     />
                     {dmtValue === "dmt1" && (
                       <Typography
                         variant="span"
                         sx={{
                           textAlign: "left",
                           display: "block",
                           color:
                             remainingTime === "Time expired"
                               ? "red"
                               : "white",
                           fontWeight: 500,
                           fontSize: "0.8rem",
                           ml: "9px",
                         }}
                       >
                         Remaining Time:{" "}
                         {formatRemainingTime(remainingTime)}
                       </Typography>
                     )}
                   </FormControl>):(
                   <FormControl fullWidth>
                   <TextField
                     placeholder="Enter Amount"
                     id="amount"
                     size="small"
                     type="number"
                     required
                     value={amount}
                     autoComplete="off"
                     sx={{ background: "white", borderRadius: 1 }}
                     InputProps={{
                       inputProps: { min: "0", max: remDailyLimit },
                       endAdornment: 
                         <InputAdornment position="end">
                           {((dmtValue === "dmt1" && amount > 10) ||
                             (dmtValue !== "dmt1"  &&  amount >= 10 )) &&
                             !isOtpShow && (
                               <Button
                                 sx={{
                                   py: 0.5,
                                   fontSize: "10px",
                                   px: 0.8,
                                 }}
                                 variant="contained"
                                 onClick={sendOtpMt}
                                 disabled={!!err?.data}
                               >
                                 Get OTP
                               </Button>
                             )}
                         </InputAdornment>
                       
                     }}
                     onChange={(e) => setAmount(e.target.value)}
                     onKeyDown={(e) => {
                       if (e.key === "+" || e.key === "-")
                         e.preventDefault();
                     }}
                   />
                   {dmtValue === "dmt1" && (
                     <Typography
                       variant="span"
                       sx={{
                         textAlign: "left",
                         display: "block",
                         color:
                           remainingTime === "Time expired"
                             ? "red"
                             : "white",
                         fontWeight: 500,
                         fontSize: "0.8rem",
                         ml: "9px",
                       }}
                     >
                       Remaining Time:{" "}
                       {formatRemainingTime(remainingTime)}
                     </Typography>
                   )}
                 </FormControl>
                   )
                   }   
                        

                        {/* OTP Input */}
                        { isOtpShow && (
                          <FormControl fullWidth>
                            <TextField
                              placeholder="Enter OTP"
                              id="otp"
                              size="small"
                              type="text"
                              value={remOtp}
                              onChange={handleOtpChange}
                              inputProps={{ maxLength: 6 }}
                              sx={{
                                background: "white",
                                borderRadius: 1,
                              }}
                              error={
                                remOtp?.length > 0 &&
                                (remOtp?.length < 4 || remOtp?.length > 6)
                              }
                              helperText={
                                remOtp?.length > 0 && remOtp?.length < 4
                                  ? "OTP must be at least 4 digits"
                                  : remOtp?.length > 6
                                  ? "OTP cannot exceed 6 digits"
                                  : ""
                              }
                              onKeyDown={(e) => {
                                if (e.key === "+" || e.key === "-")
                                  e.preventDefault();
                              }}
                            />
                          </FormControl>
                        ) }
                      </Box>

                      {/* M-PIN Input */}

                      {(isOtpShow 
                        ) && (
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: 0,
                            }}
                          >
                            <FormControl>
                              <Typography
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: { sm: "14px" },
                                }}
                              >
                                Enter M-PIN
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2, // Space between the M-PIN input and buttons
                                  mt: 0.8, // Margin above the buttons
                                }}
                              >
                                <PinInput
                                  length={6}
                                  type="password"
                                  onChange={(value) => setMpin(value)}
                                  regexCriteria={/^[0-9]*$/}
                                  style={{ padding: "6px" }}
                                  inputStyle={{
                                    background: "white",
                                    borderColor: "white",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  width: "98%",
                                  mt: 1,
                                  gap: 2,
                                }}
                              >
                                <ResetMpin variant="standard" bgColor="white" />
                                <button
                                  type="submit"
                                  style={{
                                    textTransform: "none",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    padding: "4px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  onClick={handleSubmit}
                                  disabled={remOtp?false:true}
                                >
                                  <Typography style={{ fontSize: "12px" }}>
                                    Proceed
                                  </Typography>
                                </button>
                              </Box>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                       {onComplete && view !== "Express Transfer" && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="standard"
                          className="btn-background1"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            window.open("/mt-receipt", "_blank");
                          }}
                        >
                          <Typography style={{ fontSize: "12px" }}>
                            Print Receipt
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                    </Grid>
                   
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {err && err && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            px: 2,
                            fontSize: "15px",

                            color: "#fff",
                            gap: 5,
                          }}
                        >
                          {err.message && err.message && (
                            <div>{err && err.message}</div>
                          )}

                          {err.data && err.message === "Invalid M Pin" && (
                            <div className="blink_text">
                              Attempts remaining:{err && 5 - Number(err.data)}
                            </div>
                          )}
                        </Box>
                      )}
                    </Grid>

                    {/* Transaction Results Section */}
                    <Grid item xs={12} sx={{ mt: 2, px: 2 }}>
                      {arrAmtRes.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            fontSize: "15px",
                            px: 2,
                            py: 1,
                            borderRadius: 2,
                            background: "#fff",
                            color: "white",
                            mb: 1,
                            ml: "10%",
                          }}
                        >
                          <span>₹{item.amount} :</span>
                          {item.meta.res && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Typography
                                sx={{ color: "white", fontSize: "12px" }}
                              >
                                {item.meta.res.message}
                              </Typography>
                              <AnimateIcon28 src={success_anim} />
                            </Box>
                          )}
                          {item.meta.error && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Typography
                                sx={{ color: "grey", fontSize: "12px" }}
                              >
                                {item.meta.error}
                              </Typography>
                              <AnimateIcon28 src={fail_anim} />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Grid>
                  </Grid>

                  {/* Footer Section */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",

                      pb: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {view === "upi"
                        ? "UPI Transfer"
                        : view === "Money Transfer"
                        ? `Money Transfer ${type}`
                        : view === "Express Transfer"
                        ? `Wallet Transfer ${type}`
                        : view === "Super Transfer"
                        ? `Payments Transfer ${type}`
                        : `Money Transfer ${type}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Proceed your journey with IMPS GURU
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <ConfirmationModal
            openConfirm={openConfirm}
            amount={amount}
            setOpenConfirm={setOpenConfirm}
            form="expMoneyTransfer"
            view={view}
            ben={ben}
            request={request}
            mtRequest={mtRequest}
            dmtValue={dmtValue}
          />
        </Box>
      </Modal>
    </Box>
  );
};
export default CommonMoneyTransferModal;
