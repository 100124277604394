import React from "react";
import { Dialog, IconButton, Box, Avatar, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { festivalIMage } from "../iconsImports"; // Import your Holi-themed image

const FestivalImage = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        sx: {
            backdropFilter: "blur(10px)", // Blur effect on the background
            // background: "linear-gradient(45deg, red, blue, yellow, green, orange)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          overflow: "hidden",
          background: "linear-gradient(135deg, #ff4081, #ffeb3b, #4caf50, #2196f3)",
          animation: "backgroundAnimation 6s infinite alternate ease-in-out",
        }}
      >
        {/* Close Icon */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "white",
            background: "rgba(0,0,0,0.5)",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* MUI Avatar with Colored Background */}
        {/* <Avatar
          alt="User Avatar"
          sx={{
            position: "absolute",
            top: 20,
            width: 80,
            height: 80,
            border: "4px solid white",
            background: "linear-gradient(135deg, red, blue, green)",
            boxShadow: "0 0 15px rgba(255, 0, 0, 0.8)",
            animation: "avatarGlow 2s infinite alternate ease-in-out",
          }}
        /> */}

        {/* Holi Image */}
        <Box
          component="video"
          src="/happyholi.mp4"
          autoPlay
          loop
          muted
          playsInline
          sx={{
            width: "60%",
            borderRadius: 2,
          }}
        />
        {/* Floating Color Splashes */}
        <Box className="gulal gulal-1"></Box>
        <Box className="gulal gulal-2"></Box>
        <Box className="gulal gulal-3"></Box>

        {/* Pichkari Water Gun */}
        <Box className="pichkari">
          <span className="water"></span>
        </Box>

        {/* Color Bomb Explosion */}
        <Box className="color-bomb"></Box>
      </Box>

      {/* Keyframe Animations */}
      {/* <style>
        {`
          @keyframes backgroundAnimation {
            0% {
              background: linear-gradient(135deg, #ff4081, #ffeb3b, #4caf50, #2196f3);
            }
            50% {
              background: linear-gradient(135deg, #e91e63, #ffc107, #8bc34a, #3f51b5);
            }
            100% {
              background: linear-gradient(135deg, #ff5722, #ff9800, #009688, #673ab7);
            }
          }

          @keyframes fadeInScale {
            from {
              opacity: 0;
              transform: scale(0.5);
            }
            to {
              opacity: 1;
              transform: scale(0.6);
            }
          }

          @keyframes gulalSpread {
            0% { opacity: 0; transform: scale(0); background-color: red; }
            50% { opacity: 1; transform: scale(1.5); background-color: blue; }
            100% { opacity: 0; transform: scale(2); background-color: green; }
          }

          .gulal {
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            opacity: 0;
            animation: gulalSpread 3s infinite;
          }

          .gulal-1 { top: 30%; left: 20%; animation-delay: 0s; }
          .gulal-2 { top: 50%; right: 20%; animation-delay: 0.5s; }
          .gulal-3 { bottom: 20%; left: 40%; animation-delay: 1s; }

          .pichkari {
            position: absolute;
            bottom: 50px;
            left: 10%;
            width: 100px;
            height: 40px;
            background: yellow;
            border-radius: 10px;
            transform: rotate(-20deg);
          }

          .water {
            position: absolute;
            top: -10px;
            left: 90px;
            width: 10px;
            height: 10px;
            background: cyan;
            border-radius: 50%;
            animation: waterShoot 1.5s infinite ease-in-out;
          }

          @keyframes waterShoot {
            0% { transform: translateX(0) scale(1); opacity: 1; }
            50% { transform: translateX(50px) scale(1.5); opacity: 0.8; }
            100% { transform: translateX(100px) scale(0); opacity: 0; }
          }

        //   .color-bomb {
        //     position: absolute;
        //     bottom: 30px;
        //     right: 20%;
        //     width: 20px;
        //     height: 20px;
        //     background: radial-gradient(circle, red, yellow, green);
        //     border-radius: 50%;
        //     animation: explode 3s infinite;
        //   }

          @keyframes explode {
            0% { transform: scale(1); opacity: 1; }
            50% { transform: scale(5); opacity: 0.6; }
            100% { transform: scale(8); opacity: 0; }
          }

          @keyframes avatarGlow {
            0% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.8); }
            50% { box-shadow: 0 0 15px rgba(0, 0, 255, 0.8); }
            100% { box-shadow: 0 0 15px rgba(0, 255, 0, 0.8); }
          }
        `}
      </style> */}
    </Dialog>
  );
};

export default FestivalImage;
