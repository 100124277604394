import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import AuthContext from "../store/AuthContext";
import DmtContainer from "./DMTcontainer";
import CMSView from "./CMSView";
import VendorPayments from "./VendorPayments";
import UPITransferView from "./UPITransferView";
import MobileRechargeForm from "../component/MobileRechargeForm";
import CreditcardForm from "../component/CreditcardForm";
import ElectricityForm from "../component/ElectricityForm";
import AEPS2View from "./aeps/AEPS2View";
import BBPSView from "./BBPSView";
import FlightTab from "../component/Travel/FlightTab";
import BusTab from "../component/Travel/BusTab";
import TrainTab from "../component/Travel/TrainTab";
import HotelsTab from "../component/Travel/HotelsTab";
import NewsSection from "../component/NewsSection";
import CommonCardDashBoard from "./CommonCardDashBoard";
import {
  aeps1,
  bbps_1,
  broadband_1,
  bus_1,
  cms1,
  credit_card1,
  dmt,
  dth_1,
  elec,
  electricity,
  electricity1,
  gas_1,
  hotel_1,
  insurance_1,
  landline_1,
  plane_1,
  postpaid_1,
  prepaid_1,
  train_1,
  upi_1,
  vapy_1,
  water_1,
  wallet1,
  Nepal1,
  recent,
} from "../iconsImports";
import NepalTransfer from "./NepalTransfer";
import SuperMoneyTransfer from "./SuperMoneyTransfer";
import SuperPayments from "./SuperPayments";
import CommenBBPSView from "./CommenBBPSView";

const Servicess = () => {
  // const [currentView, setCurrentView] = useState(null);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [id, setId] = useState();
  const currentView = authCtx.currentView;
  const setCurrentView = authCtx.setCurrentView;

  const [sumData, setSumData] = useState(false);
  const dataCategories = [
    {
      title: "Banking",
      data: [
        ...(user?.dmt1 === 1 || user?.dmt2 === 1
          ? [
              {
                id: 1,
                name: "DMT",
                img: dmt,
                component: DmtContainer,
              },
            ]
          : []),

        ...(user?.aeps === 1
          ? [{ id: 2, name: "AEPS ", img: aeps1, component: AEPS2View }]
          : []),

        ...(user?.dmt4 === 1
          ? [{ id: 3, name: "Wallet", img: wallet1, component: VendorPayments }]
          : []),
        ...(user?.st === 1
          ? [
              {
                id: 4,
                name: "Payments",
                img: vapy_1,
                component: SuperPayments,
              },
            ]
          : []),
        ...(user?.upi_transfer === 1
          ? [{ id: 5, name: "UPI", img: upi_1, component: UPITransferView }]
          : []),
        ...(user?.nepal_transfer === 1
          ? [
              {
                id: 6,
                name: "Nepal Transfer ",
                img: Nepal1,
                component: NepalTransfer,
              },
            ]
          : []),
        ...(user?.aeps === 1 || user?.aeps2 === 1
          ? [{ id: 7, name: "CMS", img: cms1, component: CMSView }]
          : []),
      ],
    },
    {
      title: "Utility",
      data: [
        ...[
          ...(user.recharge === 1
            ? [
                {
                  id: 8,
                  name: "Prepaid",
                  img: prepaid_1,
                  component: MobileRechargeForm,
                },

                user.recharge === 1
                  ? {
                      id: 9,
                      name: "DTH",
                      img: dth_1,
                      component: MobileRechargeForm,
                    }
                  : [],
              ]
            : []),
          {
            id: 10,
            name: "Credit Card",
            img: credit_card1,
            component: CreditcardForm,
          },
          ...(user.bbps === 1
            ? [{ id: 11, name: "BBPS", img: bbps_1, component: BBPSView }]
            : []),
        ],
      ],
    },
    {
      title: "Utility With Commission",
      data: [
        ...[
          ...(user.recharge === 1
            ? [
                // {
                //   id: 8,
                //   name: "Prepaid",
                //   img: prepaid_1,
                //   component: MobileRechargeForm,
                // },
                user.recharge === 1
                  ? {
                      id: 12,
                      name: "Postpaid",
                      img: postpaid_1,
                      component: MobileRechargeForm,
                    }
                  : [],
                // user.recharge === 1?
                // {
                //   id: 10,
                //   name: "DTH",
                //   img: dth_1,
                //   component: MobileRechargeForm,
                // }:[],
              ]
            : []),
          {
            id: 13,
            name: "Electricity",
            img: electricity1,
            component: CommenBBPSView,
          },
          // {
          //   id: 13,
          //   name: "Credit Card",
          //   img: credit_card1,
          //   component: CreditcardForm,
          // },
          {
            id: 14,
            name: "BroadBand",
            img: broadband_1,
            component: CommenBBPSView,
          },
          { id: 15, name: "Gas", img: gas_1, component: CommenBBPSView },
          { id: 16, name: "Water", img: water_1, component: CommenBBPSView },
          {
            id: 17,
            name: "Insurance",
            img: insurance_1,
            component: CommenBBPSView,
          },
          {
            id: 18,
            name: "Landline",
            img: landline_1,
            component: CommenBBPSView,
          },
          // ...(user.bbps === 1
          //   ? [{ id: 18, name: "BBPS", img: bbps_1, component: BBPSView }]
          //   : []),
        ],
      ],
    },
    {
      title: "Travel",
      data: [
        ...[
          { id: 19, name: "AIR", img: plane_1, component: FlightTab },
          { id: 20, name: "BUS", img: bus_1, component: BusTab },
          { id: 21, name: "HOTELS", img: hotel_1, component: HotelsTab },
          { id: 22, name: "IRCTC", img: train_1, component: TrainTab },
        ],
      ],
    },
  ];

  const handleCardClick = (item) => {
    setName(item.name);
    setImage(item.img);
    setId(item.id);
    let title = ""; // Define a title variable
    if (item.name === "Prepaid") {
      title = "Prepaid";
    } else if (item.name === "Postpaid") {
      title = "Postpaid";
    }
    if (item.component) {
      setCurrentView({
        component: item.component,
        type:
          item.name === "DMT1"
            ? "dmt1"
            : item.name === "DMT2"
            ? "dmt2"
            : item.name === "CMS"
            ? "cms1"
            : item.name === "Payments"
            ? "super"
            : item.name === "Wallet"
            ? "express"
            : item.name === "Nepal Transfer"
            ? "nepal"
            : item.name === "UPI"
            ? "upi"
            : // : item.name === "Prepaid"
            // ? "C00"
            item.name === "Prepaid" || item.name === "Postpaid"
            ? "mobile"
            : item.name === "DTH"
            ? "dth"
            : item.name === "Electricity"
            ? "C04"
            : item.name === "Credit Card"
            ? "C15"
            : item.name === "BroadBand"
            ? "C05"
            : item.name === "Gas"
            ? "C07"
            : item.name === "Water"
            ? "C08"
            : item.name === "Insurance"
            ? "C11"
            : item.name === "Landline"
            ? "C02"
            : item.name,
        title, // Add title prop
      });
    }
  };

  const resetView = () => {
    setCurrentView(null);
  };

  return (
<>
  {user?.aggreement === 1 ? (
    <>
      {location.pathname === "/customer/dashboard" && <NewsSection />}

      {!currentView ? (
        dataCategories.map((category, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: 1,
              border: "solid 1px lightgray",
              p: { xs: 1, sm: 3 },
              borderRadius: 3,
              overflow: "hidden", // Fix overflow issue
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              backgroundImage: `url(${recent})`,
            }}
          >
            <Typography
              variant="h6"
              align="left"
              sx={{
                pl: 1,
                mt: -2,
                fontSize: { xs: "0.9rem", sm: "1.1rem" }, // Adjusted for small screens
              }}
            >
              {category.title}
            </Typography>
            <Grid container spacing={1}>
              {category.data.map((item) => (
                <Grid
                  item
                  xs={6} // Adjusted for better spacing in small screens
                  sm={4}
                  md={3}
                  lg={2}
                  key={item.id}
                  sx={{ mb: -1 }}
                >
                  <CommonCardDashBoard
                    name={item.name}
                    img={item.img}
                    onClick={() => handleCardClick(item, index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      ) : (
        <currentView.component
          type={currentView.type}
          handleCardClick={handleCardClick}
          title={currentView.title}
          resetView={resetView}
          name={name}
          id={id}
          dataCategories={dataCategories}
          image={image}
        />
      )}
    </>
  ) : (
    <Box>
      <Grid
        item
        className="card-css"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          p: 3, // Reduced padding for smaller screens
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgb(73, 132, 200)",
          borderRadius: "10px",
          boxShadow: 3,
          width: { xs: "90%", sm: "80%", md: "600px" }, // Responsive width
          height: { xs: "auto", sm: "300px" }, // Flexible height
        }}
      >
        <Box sx={{ textAlign: "center", color: "#fff", width: "100%" }}>
          <h5
            style={{
              fontSize: "18px", // Reduced font size for small screens
              fontWeight: "600",
              marginBottom: "15px",
              letterSpacing: "1px",
            }}
          >
            To use our services, please sign the agreement
          </h5>
          <br />
          {(user.role === "Ret" || user.role === "Dd") && (
            <a
              href="/service"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#fff",
                fontSize: "16px", // Adjusted for small screens
                fontWeight: "500",
                textDecoration: "none",
                backgroundColor: "rgb(220, 42, 113)",
                padding: "6px 15px",
                borderRadius: "30px",
                display: "inline-block",
                transition: "background-color 0.3s",
                textAlign: "center",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "rgb(26, 32, 38)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "rgb(220, 42, 113)";
              }}
            >
              Click here
            </a>
          )}
        </Box>
      </Grid>
    </Box>
  )}
</>

  );
};

export default Servicess;
