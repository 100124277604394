import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import {
  Call1,
  flight1,
  LimitAcc,
  LimitTran,
  Name,
  back,
} from "../iconsImports";
import { get, postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DmrNumberListModal from "../modals/DmrNumberListModal";
import DmrAddBeneficiaryModal from "../modals/DmrAddBeneficiaryModal";
import DmrAddRemitterModal from "../modals/DmrAddRemitterModal";
import DmrVrifyNewUser from "../modals/DmrVrifyNewUser";
import BeneCardComponent from "../component/BeneCardComponent";
import Loader from "../component/loading-screen/Loader";
import NameChangeModal from "../modals/NameChangeModal";
import { PATTERNS } from "../utils/ValidationUtil";
import BeneSearchBar from "../component/BeneSearchBar";
import { useEffect } from "react";
import { currencySetter } from "../utils/Currencyutil";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import OutletRegistration from "../component/OutletRegistration";
import { banking } from "../_nav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HNavButton from "../component/HNavButton";
import { useNavigate } from "react-router-dom";
import useResponsive from "../hooks/useResponsive";
import { BBPS, teamWork } from "../iconsImports";
import NoDataView from "../component/NoDataView";
import DmtAddRemModal from "../modals/DmtAddRemModal";
import RemitterKyc from "../modals/RemitterKyc";
import CustomTabs from "../component/CustomTabs";
import { mt_tab_value } from "../utils/constants";
import CommonCardDashBoard from "./CommonCardDashBoard";
import CommonCardServices from "./CommonCardServices";
import BbpsCardComponent from "../component/BbpsCardComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useCommonContext from "../store/CommonContext";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const DmtContainer = ({
  setMoney = false,
  id,
  resetView,
  dataCategories,
  handleCardClick,
}) => {
  const [infoFetchedMob, setInfoFetchedMob] = useState(false);
  const [request, setRequest] = useState(false);
  const [remitterStatus, setRemitterStatus] = useState();
  const ismobile = useResponsive("down", "md");
  console.log("id", id);

  const [search, setSearch] = useState("");
  const [mobile, setMobile] = useState("");
  const [bene, setBene] = useState([]);
  const [filteredBenelist, setFilteredBenelist] = useState([]);
  const [otpRefId, setOtpRefId] = useState("");
  const [verifyotp, setVerifyotp] = useState(false);
  const [addNewRem, setAddNewRem] = useState(false);
  const [isValAccNum, setisValAccNum] = useState(true);
  const [isMobv, setIsMobv] = useState(true);
  const [dmr2RemRes, setDmr2RemRes] = useState("");
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const userLat = authCtx.location.lat;
  const userLong = authCtx.location.long;
  const [numberList, setNumberList] = useState([]);
  const [openRemKyc, setOpenRemKyc] = useState(false);
  const [remRefKey, setRemRefKey] = useState({});
  const [addNewRemDmt3, setAddNewRemDmt3] = useState(false);
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState(0);
  const [type, settype] = useState(user?.dmt1 === 1 ? "dmt1" : "dmt2");
  const { setDynamicWadh, dynamicWadh } = useCommonContext();

  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const handleCloseKycModal = () => {
    setOpenRemKyc(false); // Close the modal when this function is called
  };
  const handleBack = () => {
    resetView(false);
  };
  useEffect(() => {
    if (search) {
      const myList = bene.filter((item) => {
        // console.log("item", item);
        return item.name
          ? item.name.toUpperCase().includes(search.toUpperCase())
          : item.bene_name.toUpperCase().includes(search.toUpperCase());
      });
      setFilteredBenelist(myList);
    } else {
      setFilteredBenelist(bene);
    }

    return () => {};
  }, [search, bene]);

  const getRemitterStatus = (number) => {
    console.log("calling api");
    const apiEnd =
      type == "dmt1"
        ? ApiEndpoints.GET_REMMITTER_STATUS_NEW
        : type == "dmt2"
        ? ApiEndpoints.DMT2_REM_STAT
        : ApiEndpoints.GET_REMMITTER_STATUS_DMT3;

    postJsonData(
      apiEnd,
      {
        number: number,
        type: "M",
        latitude: userLat,
        longitude: userLong,
      },
      setRequest,
      (res) => {
        if (res && res.status === 200 && res.data.message === "OTP Sent") {
          setOtpRefId(res.data.otpReference);
          setVerifyotp(true);
        } else if (res && res.data && res.data) {
          const data = type === "dmt1" ? res.data.remitter : res?.data?.data;
          setMobile(number);
          setRemitterStatus(type === "dmt1" ? data : data.remitter);
          setBene(type === "dmt1" ? data?.beneficiaries : data?.data2.data);
          setInfoFetchedMob(true);
          setNumberList("");
        } else {
          console.log("im here3");
          setRemitterStatus();
        }
      },
      (error) => {
        console.log("calling api---->", error);

        if (error && error) {
          if (
            error.response.status === 404 &&
            error.response.data.message === "Please do remitter e-kyc."
          ) {
            if (type == "dmt2") {
              console.log("errorin", error);
              setOpenRemKyc(true);
              // setOtpRefId(error?.response?.data?.otpReference);
            }
          }
          if (
            error.response.status === 404 &&
            error.response.data.message === "Remitter Not Found"
          ) {
            if (type === "dmt1") {
              setRemRefKey(error.response.data.data);
              setDynamicWadh(error?.response?.data?.data?.pidOptionWadh);
            }
            setAddNewRem(true);
          }
          if (
            error.response.status == 404 &&
            error.response.data.message ==
              "Please validate your aadhaar number."
          ) {
            console.log("im inside dmt3");
            setAddNewRemDmt3(true);
          }
          if (error?.response?.data?.step == 3) {
            console.log("im here");
            setVerifyotp(true);
            setDmr2RemRes(error?.response?.data?.data);
          }
        }
      }
    );
  };
  const goBack = () => {
    setInfoFetchedMob(false);
    setRemitterStatus();
    setMobile("");
  };
  const returnMoneyNew = () => {
    setInfoFetchedMob(false);
  };

  console.log("Sss", dynamicWadh);
  const refreshRemitterStatus = (number) => {
    postJsonData(
      ApiEndpoints.REF_REMMITTER_STATUS,
      {
        number: number,
        type: "M",
      },
      setRequest,
      (res) => {
        if (res && res.status === 200 && res.data.message === "OTP Sent") {
          setOtpRefId(res.data.otpReference);
          setVerifyotp(true);
        } else if (res && res.data && res.data.remitter) {
          const data = res.data.remitter;
          setMobile(number);
          setRemitterStatus(data);
          setBene(data.beneficiaries);
          setInfoFetchedMob(true);
          setNumberList("");
        } else {
          setRemitterStatus();
        }
      },
      (error) => {
        if (error && error) {
          if (
            error.response.status === 404 &&
            error.response.data.message === "Remitter Not Found"
          ) {
            setAddNewRem(true);
          } else {
            // apiErrorToast(error);
          }
        }
      }
    );
  };
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const getRemitterStatusByAcc = (event) => {
    event.preventDefault();
    const number = document.getElementById("acc").value;
    postJsonData(
      ApiEndpoints.GET_REMMITTER_STATUS_ACC,
      {
        number: number,
      },
      setRequest,
      (res) => {
        if (res && res.data) {
          const data = res.data.data;
          if (data?.length > 0) {
            setNumberList(data);
            document.getElementById("acc").value = "";
            document.getElementById("acc").focus = "off";
          } else {
            apiErrorToast("No Beneficiary Found! Kindly Change Account Number");
          }
        } else {
          setRemitterStatus();
        }
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };
  const tabs = [
    user?.dmt1 === 1 && { label: "Dmt 1" },
    user?.dmt2 === 1 && { label: "Dmt 2" },
  ].filter(Boolean);

  const handleChange = (event, newValue) => {
    console.log("newval", newValue);
    setValue(newValue);
    settype(mt_tab_value[newValue]);
    setCurrentType(newValue);

    console.log("vendor type", type);
  };
  // eslint-disable-next-line no-unused-vars
  const ekycCall = () => {
    get(
      ApiEndpoints.EKYC_INITIATE,
      `rem_mobile=${mobile && mobile}`,
      setRequest,
      (res) => {
        const data = res.data;
        window.open(data.url);
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };
  console.log("type is", type);

  console.log("rem", remitterStatus);
  return (
    <>
      {user && !user.instId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <OutletRegistration autoOpen />
        </Box>
      )}

      {user && user.instId && (
        <>
          {/* {user?.layout && user?.layout === 2 && (
            <Box
              className="card-css"
              sx={{
                width: "100%",
                my: 2,
                p: 2,
                py: 1,
                mt: 12,
              }}
            >
              <Typography className="services-heading">
                Banking Services
              </Typography>
              <Grid container>
                {user?.st === 0 ||
                user.dmt4 === 0 ||
                user?.aeps === 0 ||
                user?.nepal_transfer === 0 ||
                user?.upi_transfer === 0
                  ? banking
                      .filter((item) => {
                        if (user?.st === 0 && item.title === "Super Transfer") {
                          return undefined;
                        }
                        if (
                          user?.dmt4 === 0 &&
                          item.title === "Express Transfer"
                        ) {
                          return undefined;
                        }
                        if (user?.aeps === 0 && item.title === "AEPS") {
                          return undefined;
                        }
                        if (
                          user?.nepal_transfer === 0 &&
                          item.title === "Nepal Transfer"
                        ) {
                          return undefined;
                        }
                        if (
                          user?.upi_transfer === 0 &&
                          item.title === "UPI Transfer"
                        ) {
                          return undefined;
                        } else {
                          return item;
                        }
                      })
                      .map((mitem, index) => {
                        return (
                          <Grid
                            item
                            md={2}
                            key={index}
                            onClick={() => navigate(mitem.to)}
                            className="horizontal-sidenav"
                          >
                            <HNavButton item={mitem} />
                          </Grid>
                        );
                      })
                  : banking.map((item, index) => {
                      return (
                        <Grid
                          item
                          md={2}
                          key={index}
                          onClick={() => navigate(item.to)}
                          className="horizontal-sidenav"
                        >
                          <HNavButton item={item} />
                        </Grid>
                      );
                    })}
              </Grid>
            </Box>
          )} */}
          <div
            style={{
              height: "90vh",
              alignItems: infoFetchedMob
                ? "flex-start"
                : user?.layout && user?.layout === 2
                ? "start"
                : "center",
            }}
            className="position-relative"
          >
            <Loader circleBlue loading={request} />
            {/* <Loaders/> */}
            {/* initial form */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ mb: { md: 2, sm: 2, xs: 2 } }}
              >
                {dataCategories
                  ?.filter((category) => category.title === "Banking")
                  .map((category, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        margin: "0 auto",
                        marginBottom: 1,
                        border: "1px solid lightgray",
                        padding: { lg: 1.5, md: 1.2, xs: 2, sm: 3 },
                        borderRadius: 3,

                        paddingRight: 1,
                        flexWrap: "wrap",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        align="left"
                        sx={{
                          ml: 0.5,
                          mt: "-8px",
                          fontSize: { xs: "1rem", sm: "1.25rem" },
                        }}
                      >
                        {category.title}
                      </Typography>

                      <Box
                        sx={{
                          // overflowX: "scroll",
                          height: "100px",
                          display: "flex",
                          mt: -2,
                          flexDirection: "row",
                          width: {
                            lg: "67vw",
                            md: "67vw",
                            sm: "82vw",
                            xs: "82vw",
                          },
                        }}
                      >
                        <IconButton
                          onClick={() => handleScroll("left")}
                          sx={{
                            position: "absolute",
                            left: 10,
                            mt: 4,
                            zIndex: 1,
                            color: "#000",
                          }}
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                        <Box
                          ref={scrollContainerRef}
                          sx={{
                            display: "flex",
                            width: "100%",
                            overflowX: "auto",
                            scrollBehavior: "smooth",
                            padding: 1,
                            "&::-webkit-scrollbar": {
                              display: "none", // Hide the scrollbar
                            },
                          }}
                        >
                          {category.data.map((item) => (
                            <Box
                              sx={{
                                px: 1,
                              }}
                            >
                              <CommonCardServices
                                title={item.name}
                                img={item.img}
                                isSelected={id === item.id}
                                onClick={() => handleCardClick(item)}
                                width="200px"
                              />
                            </Box>
                          ))}
                        </Box>
                        <IconButton
                          onClick={() => handleScroll("right")}
                          sx={{
                            position: "absolute",
                            right: 0,
                            mt: 4,
                            zIndex: 1,
                            color: "#000",
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}

                {!infoFetchedMob && (
                  <CustomTabs
                    tabs={tabs}
                    value={value}
                    onChange={handleChange}
                  />
                )}
                <Card
                  className="card-css"
                  sx={{
                    width: "100%",
                    px: 1.2,
                    py: 1.2,
                  }}
                >
                  {!infoFetchedMob &&
                    !infoFetchedMob &&
                    !addNewRem &&
                    !addNewRem &&
                    !verifyotp &&
                    !verifyotp && (
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mr: 1.5,
                          mt: 1.5,
                        }}
                      >
                        {dataCategories && (
                          <ArrowBackIcon onClick={handleBack} />
                        )}
                        {/* <Button
                          size="small"
                          id="verify-btn"
                          className="button-props"
                          onClick={handleBack}
                        >
                          <span style={{ marginRight: "5px" }}>Home</span>
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "18px", height: "20px" }}
                          />
                        </Button> */}
                        <Grid
                          item
                          xs={10} // Occupy most space for centering Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center", // Center the text
                            alignItems: "center",
                          }}
                        >
                          {!mobile ? (
                            <Typography
                              sx={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                letterSpacing: "0.05rem",
                                textAlign: "center",
                              }}
                            >
                              {type === "dmt1"
                                ? "Domestic Money Transfer 1"
                                : "Domestic Money Transfer 2"}
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                    )}
                  <div>
                    <Grid
                      container
                      sx={{
                        p: 1,
                        "& .MuiTextField-root": { mt: 2 },
                        objectFit: "contain",
                        overflowY: "scroll",
                      }}
                    >
                      <Grid container xs={12}>
                        {!infoFetchedMob && !infoFetchedMob && (
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              autoComplete="off"
                              size="small"
                              label="Mobile Number"
                              id="mobile"
                              name="mobile"
                              type="number"
                              value={mobile}
                              required
                              onChange={(e) => {
                                setIsMobv(PATTERNS.MOBILE.test(e.target.value));
                                if (e.target.value === "") setIsMobv(true);
                                setMobile(e.target.value);
                                if (e.target.value === "") {
                                  setRemitterStatus("");
                                  setInfoFetchedMob(false);
                                  bene && setBene([]);
                                }
                                if (e.target.value.length === 9) {
                                  setRemitterStatus("");
                                  setInfoFetchedMob(false);
                                  bene && setBene([]);
                                }
                                if (PATTERNS.MOBILE.test(e.target.value)) {
                                  getRemitterStatus(e.target.value);
                                }
                              }}
                              error={!isMobv}
                              helperText={
                                !isMobv ? "Enter valid Mobile Number" : ""
                              }
                              inputProps={{
                                form: {
                                  autocomplete: "off",
                                  maxLength: 10,
                                  inputMode: "numeric",
                                },
                              }}
                              disabled={request && request && true}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      {!infoFetchedMob && !infoFetchedMob && (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            textAlign="center"
                            sx={{
                              width: "100%",
                              mt: 1,
                            }}
                          >
                            OR
                          </Typography>
                        </Grid>
                      )}
                      {!infoFetchedMob && !infoFetchedMob && (
                        <Box
                          component="form"
                          id="seachRemByAcc"
                          onSubmit={getRemitterStatusByAcc}
                          validate
                          sx={{ width: "100%" }}
                        >
                          <Grid item md={12} xs={12}>
                            <FormControl sx={{ width: "100%", mt: -1 }}>
                              <TextField
                                label="Account Number"
                                id="acc"
                                required
                                autoComplete="off"
                                size="small"
                                error={!isValAccNum}
                                helperText={
                                  !isValAccNum ? "Invalid Account Number" : ""
                                }
                                onChange={(e) => {
                                  setisValAccNum(
                                    PATTERNS.ACCOUNT_NUMBER.test(e.target.value)
                                  );
                                  if (e.target.value === "")
                                    setisValAccNum(true);
                                }}
                                disabled={request && request ? true : false}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Button
                              form="seachRemByAcc"
                              className="btn-background"
                              type="submit"
                              sx={{
                                width: "100%",
                                my: 3,
                              }}
                              endIcon={<ArrowForwardIosIcon />}
                              disabled={request && request ? true : false}
                            >
                              Proceed
                            </Button>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                    {infoFetchedMob && infoFetchedMob && (
                      <>
                        <Grid width="100%">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "-20px",
                            }}
                          >
                            {dataCategories && (
                              <ArrowBackIcon onClick={handleBack} />
                            )}
                            {/* <Button
                              className="refresh-icon-risk"
                              variant="outlined"
                              startIcon={<ArrowBackIcon />}
                              sx={{
                                display: "flex",
                                alignItems: "left",
                              }}
                              onClick={() => {
                                setInfoFetchedMob(false);
                                setRemitterStatus();
                                setMobile("");
                              }}
                            >
                              Back
                            </Button> */}

                            <h1
                              style={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#1877f2",
                                textAlign: "center",
                                margin: "0 auto",
                              }}
                            >
                              {type == "dmt1"
                                ? "Domestic Money Transfer 1"
                                : "Domestic Money Transfer 2"}
                            </h1>
                          </div>
                          <Grid
                            className="remitter-card"
                            container
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 2.5,
                              width: "95.5%",
                            }}
                          >
                            {/* Name and Number Section */}
                            <Grid
                              item
                              lg={4.5}
                              md={4.5}
                              sm={4.5}
                              xs={4.5}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2, // Adds spacing between items in this grid
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  component="img"
                                  src={Name}
                                  alt="Name"
                                  sx={{
                                    maxWidth: "25px",
                                    maxHeight: "50px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {type === "dmt2"
                                    ? remitterStatus?.fname
                                    : remitterStatus?.firstName}
                                  {type === "dmt2"
                                    ? remitterStatus?.lname
                                    : remitterStatus?.lastName}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  component="img"
                                  src={Call1}
                                  alt="Call"
                                  sx={{
                                    maxWidth: "25px",
                                    maxHeight: "50px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {type === "dmt2"
                                    ? remitterStatus?.mobile
                                    : remitterStatus?.mobileNumber}
                                </Typography>
                                <BorderColorIcon
                                  sx={{
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                  }}
                                  onClick={returnMoneyNew}
                                />
                              </Box>
                            </Grid>

                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              sx={{
                                display: "flex",
                                justifyContent: "center", // Centers content horizontally
                                alignItems: "center", // Centers content vertically
                              }}
                            >
                              <DmrAddBeneficiaryModal
                                type={type}
                                
                                rem_mobile={mobile}
                                getRemitterStatus={
                                  type === "dmt1"
                                    ? getRemitterStatus
                                    : getRemitterStatus
                                }
                                apiEnd={
                                  type === "dmt1"
                                    ? ApiEndpoints.ADD_BENE
                                    : ApiEndpoints.DMT2_ADD_BENE
                                }
                                view="MT_View"
                                sx={{ width: "auto" }} // Responsive width for the modal
                              />
                            </Grid>

                            <Grid
                              item
                              lg={4.5}
                              md={4.5}
                              sm={4.5}
                              xs={4.5}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2, // Adds consistent spacing between rows
                                alignItems: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: 1,
                                  mr: 1.8,
                                }}
                              >
                                <Box
                                  component="img"
                                  src={LimitAcc}
                                  alt="Limit"
                                  sx={{
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Limit Available:
                                </Typography>

                                <Typography sx={{ fontWeight: "bold" }}>
                                  {type === "dmt2"
                                    ? remitterStatus?.limit
                                    : remitterStatus?.limitTotal}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  component="img"
                                  src={LimitTran}
                                  alt="Limit"
                                  sx={{
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Limit Per Transaction:
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {type === "dmt2"
                                    ? 5000
                                    : remitterStatus?.limitPerTransaction}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              mb: { md: 2, sm: 2, xs: 2 },
                              mx: { md: 2, sm: 1, xs: 0, lg: 2.8 },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                mx: { md: 2, sm: 1, xs: 0 },
                                mr: { xs: 1.3, md: 2 },
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 1, // Ensure it takes available space
                                  maxWidth: {
                                    lg: "100%",
                                    md: "200px",
                                    sm: "200px",
                                    xs: "100%",
                                  }, // Adjust max-width based on screen size
                                }}
                              >
                                <BeneSearchBar setSearch={setSearch} />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  mx: { md: 2, sm: 1, xs: 0 },
                                  mr: { xs: 1.3, md: 2 },
                                }}
                              >
                                <Typography sx={{ fontSize: "18px", mb: 1 }}>
                                  Beneficiary List ({filteredBenelist.length})
                                </Typography>
                              </Box>
                            </Box>
                            <div
                              className="
                       enable-scroll "
                              style={{
                                overflow: "auto", // Ensure that the overflow behavior is automatic
                                height: "85vh", // Fixed height to control the scrolling area
                                paddingBottom: "8px",
                                "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for Webkit browsers
                                msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
                                scrollbarWidth: "none",
                              }}
                            >
                              {bene?.length <= 0 ? (
                                <Typography sx={{ mt: 2 }}>
                                  No Beneficiary found.
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Enter Remitter's Mobile Number to view
                                    Beneficiary List
                                  </Typography>
                                  <NoDataView />
                                </Typography>
                              ) : filteredBenelist?.length <= 0 ? (
                                <Typography sx={{ mt: 2 }}>
                                  No Beneficiary found.
                                </Typography>
                              ) : (
                                <Grid container spacing={0.5}>
                                  {filteredBenelist?.map((ben, index) => {
                                    return (
                                      <Grid
                                        item
                                        md={12}
                                        lg={12}
                                        sm={12}
                                        xs={12}
                                      >
                                        <BeneCardComponent
                                          type={type}
                                          ben={ben}
                                          index={index}
                                          mobile={mobile}
                                          remitterStatus={remitterStatus}
                                          getRemitterStatus={getRemitterStatus}
                                          view="MT_View"
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                // filteredBenelist.map((ben, index) => {
                                //   return (
                                //     <BeneCardComponent
                                //       type={type}
                                //       ben={ben}
                                //       index={index}
                                //       mobile={mobile}
                                //       remitterStatus={remitterStatus}
                                //       getRemitterStatus={getRemitterStatus}
                                //       view="MT_View"
                                //     />
                                //   );
                                // })
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Box
                      component="form"
                      id="seachRemByAcc"
                      onSubmit={getRemitterStatusByAcc}
                      validate
                      sx={{ width: "100%" }}
                    ></Box>
                    {/* </div> */}
                  </div>

                  {/* {numberList && numberList.length > 0 && ( */}
                  <DmrNumberListModal
                    numberList={numberList}
                    setMobile={(mob) => {
                      setMobile(mob);
                      getRemitterStatus(mob);
                    }}
                  />
                  {/* )} */}
                </Card>
              </Grid>
            </Grid>

            {addNewRem && addNewRem && (
              <DmtAddRemModal
                rem_mobile={mobile}
                getRemitterStatus={getRemitterStatus}
                apiEnd={
                  type === "dmt1"
                    ? ApiEndpoints.ADD_REM
                    : ApiEndpoints.DMT2_ADD_REM
                }
                view="moneyTransfer"
                dmtValue={type}
                setAddNewRem={setAddNewRem}
                otpRef={otpRefId}
                setOtpRef={setOtpRefId}
                remRefKey={remRefKey}
                setRemRefKey={setRemRefKey}
              />
            )}
            {verifyotp && verifyotp && (
              <DmrVrifyNewUser
                rem_mobile={mobile}
                getRemitterStatus={getRemitterStatus}
                view="moneyTransfer"
                verifyotp={verifyotp}
                dmr2RemRes={dmr2RemRes}
                setDmr2RemRes={setDmr2RemRes}
                setVerifyotp={setVerifyotp}
                apiEnd={
                  type === "dmt2"
                    ? ApiEndpoints.DMT2_REGISTER_REM
                    : ApiEndpoints.VALIDATE_OTP
                }
                otpRefId={otpRefId}
                setOtpRefId={setOtpRefId}
                dmtValue={type}
                goBack={goBack}
              />
            )}
            {openRemKyc && type == "dmt2" && (
              <RemitterKyc
                open={openRemKyc}
                onClose={handleCloseKycModal}
                remRefKey={remRefKey}
                rem_mobile={mobile}
                dmtValue={type}
                setVerifyotp={setVerifyotp}
                setDmr2RemRes={setDmr2RemRes}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default DmtContainer;
